import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    unapproved: []
}

export const fieldWithStatusSlice = createSlice({
    name: "fieldWithStatus",
    initialState,
    reducers: {
        updateFieldsStatusState: (state, action) => {
            const payload = action.payload
            Object.keys(payload).map(key => state[key] = payload[key])
        },
    },
});

export const { updateFieldsStatusState } = fieldWithStatusSlice.actions

export const selectFieldsWithState = (state) => state.fieldWithStatus;

export default fieldWithStatusSlice.reducer;