import LoginForm from "../components/LoginForm/LoginForm";
import MapService from "../API/service";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { setBusyState } from "../helpers/index.js"

export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onSubmit = async (login, password) => {
    /**
     * {accessToken, refreshToken}
     */
    try {
      const result = await authenticate(login, password);
      if (login && password && result) {
        localStorage.setItem("creds", JSON.stringify({ login, password }));
        localStorage.setItem("tokens", JSON.stringify(result));

        setBusyState(dispatch, false);
        navigate(result.user.role === "client" ? "/map" : "/");
      }
    } catch (e) {
      setBusyState(dispatch, false);
      alert(e);
    }
  };
  const authenticate = (login, password) => {
    setBusyState(dispatch, true);

    return MapService.signin(login, password);
  };

  setBusyState(dispatch, false);

  return (
    <section
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/login.jpg"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
      }}
      className="login-section"
    >
      <LoginForm onSubmit={onSubmit} />
    </section>
  );
}
