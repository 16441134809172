import axios from "axios";

import { refreshTokenFn } from './refreshToken'

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`;

axios.interceptors.request.use(
    async (config) => {
        const tokens = JSON.parse(localStorage.getItem("tokens"));

        if (tokens?.accessToken) {
            config.headers = {
                ...config.headers,
                authorization: `Bearer ${tokens?.accessToken}`,
            };
        }

        return config;
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        console.log(error)
        const config = error?.config;

        if (error?.response?.status === 401 && !config?.sent) {
            config.sent = true;

            const result = await refreshTokenFn();

            if (result?.accessToken) {
                config.headers = {
                    ...config.headers,
                    authorization: `Bearer ${result?.accessToken}`,
                };
            }

            return axios(config);
        }
        return Promise.reject('intercepter...', error);
    }
);

export const axiosPrivate = axios;