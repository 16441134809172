import { axiosPublic } from './axiosPublic'

export const refreshTokenFn = async () => {
    const tokensStored = JSON.parse(localStorage.getItem("tokens"));
    try {
        const response = await axiosPublic.post("/users/refresh", {
            refreshToken: tokensStored?.refreshToken,
        });
        console.log(response)
        const tokens = response.data;

        if (!tokens?.accessToken) {
            localStorage.removeItem("tokens");
            localStorage.removeItem("user");
        }

        localStorage.setItem("tokens", JSON.stringify(tokens));

        return tokens;
    } catch (error) {
        localStorage.removeItem("tokens");
        localStorage.removeItem("tokens");
    }
};
