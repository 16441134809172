import styles from "./SecondStep.module.css";
import { FormLabel, ContentSwitcher, Switch, TextArea, Dropdown, MultiSelect } from "@carbon/react";
import { WELL_TYPE, PRODUCTION_DRIVE, WELL_INTEGRITY_SERVICE, WELL_INTEGRITY_SERVICE_TO_SERVICE_TYPE, REPAIR_SERVICES, WORK_REQUIRED } from "../../data/fields";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectReport, updateReportState } from "../../redux/reducers/ReportSlice";
import { useState } from "react";

export default function SecondStep() {
  const dispatch = useAppDispatch();
  const report = useAppSelector(selectReport);
  const [workDescriptionVisible, setWorkDescriptionVisible] = useState(true);

  const onFieldChange = (fieldName, value = "") => {
    if (fieldName === "extraWorkRequired" && value === "No") {
      dispatch(updateReportState({ ...report, "repairService": [], [fieldName]: value }));
    } else if (fieldName === "workshopWork" && value === "No") {
      dispatch(updateReportState({ ...report, "workshopWorkDescriptions": "", [fieldName]: value }));
    }
    else {
      dispatch(updateReportState({ ...report, [fieldName]: value }));
    }
  };

  return (
    <>
      <div className={styles["flex-content"]}>
        <Dropdown
          className={styles["flex-item"]}
          onChange={(e) => {
            onFieldChange("wellType", e.selectedItem);
          }}
          id="wellType"
          items={WELL_TYPE}
          itemToString={(item) => item || ""}
          titleText="Well type"
          selectedItem={report.wellType || null}
        />
        <Dropdown
          className={styles["flex-item"]}
          onChange={(e) => {
            onFieldChange("productionDrive", e.selectedItem);
          }}
          id="productionDrive"
          items={PRODUCTION_DRIVE}
          itemToString={(item) => item || ""}
          titleText="Production drive"
          selectedItem={report.productionDrive || null}
        />
      </div>
      <Dropdown
        onChange={(e) => {
          onFieldChange("wellIntegrityService", e.selectedItem);
        }}
        id="wellIntegrityService"
        items={WELL_INTEGRITY_SERVICE}
        itemToString={(item) => item || ""}
        titleText="Well integrity services"
        selectedItem={report.wellIntegrityService || null}
      />
      <Dropdown
        onChange={(e) => {
          onFieldChange("serviceType", e.selectedItem);
        }}
        id="serviceType"
        items={WELL_INTEGRITY_SERVICE_TO_SERVICE_TYPE[report.wellIntegrityService] || []}
        itemToString={(item) => item || ""}
        titleText="Service type"
        selectedItem={WELL_INTEGRITY_SERVICE_TO_SERVICE_TYPE[report.wellIntegrityService].includes(report.serviceType) ? report.serviceType : WELL_INTEGRITY_SERVICE_TO_SERVICE_TYPE[report.wellIntegrityService][0]}
      />
      <div className={styles["radio-div"]}>
        <FormLabel>Well head clamp installation</FormLabel>
        <ContentSwitcher
          className={styles.switcher}
          selectedIndex={
            report.wellHeadRemedialClampRequired
              ? ["Not Required", "Required", "Not Checked"].findIndex((v) => v && v === report.wellHeadRemedialClampRequired)
              : 0
          }
          onChange={(e) => {
            onFieldChange("wellHeadRemedialClampRequired", e.name);
          }}
        >
          <Switch name={"Not Required"} text="Not Required" />
          <Switch name={"Required"} text="Required" />
          <Switch name={"Not Checked"} text="Not Checked" />
        </ContentSwitcher>
      </div>
      <div className={styles["radio-div"]}>
        <FormLabel>Cellar available</FormLabel>
        <ContentSwitcher
          selectedIndex={
            report.cellarAvailability && report.cellarAvailability !== "default"
              ? ["Yes", "No"].findIndex((v) => v === report.cellarAvailability)
              : 0
          }
          onChange={(e) => {
            onFieldChange("cellarAvailability", e.name);
          }}
          className={styles.switcher}
        >
          <Switch name={"Yes"} text="Yes" />
          <Switch name={"No"} text="No" />
        </ContentSwitcher>
      </div>
      <div className={styles["radio-div"]}>
        <FormLabel>Cellar required</FormLabel>
        <ContentSwitcher
          className={styles.switcher}
          selectedIndex={
            report.cellarRequired && report.cellarRequired !== "default"
              ? ["Yes", "No"].findIndex((v) => v && v === report.cellarRequired)
              : 0
          }
          onChange={(e) => {
            onFieldChange("cellarRequired", e.name);
          }}
        >
          <Switch name={"Yes"} text="Yes" />
          <Switch name={"No"} text="No" />
        </ContentSwitcher>
      </div>
      <div className={styles["radio-div"]}>
        <FormLabel>Extra work required</FormLabel>
        <ContentSwitcher
          className={styles.switcher}
          selectedIndex={
            report.extraWorkRequired === "No" ? 1 : 0
          }
          onChange={(e) => {
            onFieldChange("extraWorkRequired", e.name);
          }}
        >
          <Switch name={"Yes"} text="Yes" />
          <Switch name={"No"} text="No" />
        </ContentSwitcher>
      </div>
      <MultiSelect
        id={"repairServices"}
        items={REPAIR_SERVICES}
        itemToString={(item) => item || ""}
        onChange={(e) => {
          onFieldChange("repairService", e.selectedItems);
        }}
        initialSelectedItems={report.repairService}
        disabled={report.extraWorkRequired === "No"}
        key={report?.repairService?.length}
      />
      <div className={styles["radio-div"]}>
        <FormLabel>Workshop work</FormLabel>
        <ContentSwitcher
          className={styles.switcher}
          selectedIndex={report.workshopWork === "No" ? 1 : 0}
          onChange={(e) => {
            onFieldChange("workshopWork", e.name);
            setWorkDescriptionVisible(e.name === "Yes");
          }}
        >
          <Switch name={"Yes"} text="Yes" />
          <Switch name={"No"} text="No" />
        </ContentSwitcher>
      </div>
      {/* workshopWorkDescriptions lisciy hui pizdani eto pole ya tebe ukazal chto ia zdu */}
      <TextArea
        disabled={!workDescriptionVisible || report.workshopWork === "No"}
        value={report.workshopWorkDescriptions}
        onChange={(e) => { onFieldChange("workshopWorkDescriptions", e.target.value) }}
        labelText="Description"
        placeholder="Work description text"
        rows={4}
        maxCount={400}
        enableCounter={true}
        id="ww-description"
      />
    </>
  );
}
