import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fields: [],
  me: {},
  fieldsDefault: [],
  isMeterOnComplianceMode: true
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    changeMeter: (state) => {
      state.isMeterOnComplianceMode = !state.isMeterOnComplianceMode;
    },
    updateMapState: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
    updateToDefault: (state) => {
      for (const [key, value] of Object.entries(initialState)) {
        state[key] = value;
      }
    },
  },
});

export const { updateMapState, updateToDefault, changeMeter } = mapSlice.actions;

export const selectMap = (state) => state.map;

export default mapSlice.reducer;
