import { useState } from "react";
import {
  FormLabel,
  ContentSwitcher,
  Switch,
  TextInput,
  ComboBox,
  Dropdown,
  Button,
  Modal,
  FlexGrid,
  Column,
  Row,
  ModalFooter,
  ModalBody,
} from "@carbon/react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setBusy } from "../../redux/reducers/BusySlice";

import {
  selectReport,
  updateReportState,
} from "../../redux/reducers/ReportSlice.js";
import {
  selectReportContent,
  updateReportContentState,
} from "../../redux/reducers/ReportContentSlice.js";

import { getCurrentState, parseLocation, validateLocation } from "./helpers";

import LocationChange from "./locationFieldChange.jsx";

import MapService from "../../API/service";

export default () => {
  const dispatch = useAppDispatch();
  const report = useAppSelector(selectReport);
  const {
    wells,
    fields,
    clients,
    well,
    field,
    client,
    disableFieldChoose,
    changes,
  } = useAppSelector(selectReportContent);
  const [showNewFieldInput, setNewFieldInput] = useState(false);
  const [showEditFieldInput, setEditFieldInput] = useState(false);
  const [isNewFieldNameValid, setNewFieldNameValid] = useState(false);
  const [isEditFieldNameValid, setEditFieldNameValid] = useState(false);

  const defaultField = {
    clientId: client.id,
    name: "",
  };

  const handleIntentionToAddNewEntity = () => {
    setNewFieldInput(!showNewFieldInput);
    setEditFieldInput(false);
    setEditFieldNameValid(false);
    if (!showNewFieldInput) {
      dispatch(
        updateReportContentState({
          field: report.well.field,
          wells: fields.find(
            (fieldOnFind) => fieldOnFind.name === report.well.field.name
          ).wells,
          disableFieldChoose:
            !showEditFieldInput && !!showNewFieldInput ? false : true,
        })
      );
    } else {
      dispatch(
        updateReportContentState({
          field: defaultField,
          wells: [],
          disableFieldChoose:
            !showEditFieldInput && !!showNewFieldInput ? false : true,
        })
      );
    }
  };

  const handleIntentionToEditFieldName = () => {
    setEditFieldInput(!showEditFieldInput);
    setNewFieldInput(false);
    dispatch(
      updateReportContentState({
        field: report.well.field,
        wells: fields.find(
          (fieldOnFind) => fieldOnFind.name === report.well.field.name
        ).wells,
        disableFieldChoose:
          !showNewFieldInput && !!showEditFieldInput ? false : true,
      })
    );
  };

  const handleTypeNewFieldName = (e) => {
    setNewFieldNameValid(false);
    setEditFieldNameValid(false);
    dispatch(updateReportContentState({ field: { name: e.target.value } }));
  };

  const handleTypeEditFieldName = (e) => {
    setNewFieldNameValid(false);
    setEditFieldNameValid(false);
    dispatch(
      updateReportContentState({ field: { ...field, name: e.target.value } })
    );
  };

  const handleValidationNewField = async () => {
    const fieldNameValid = field.name.length && field.name;
    if (!fieldNameValid) {
      setEditFieldNameValid(false);
      return;
    }
    const isLocationValid = validateLocation(field.location);
    if (!isLocationValid) {
      setEditFieldNameValid(false);
      return;
    }
    const [latitude, longitude] = parseLocation(field.location);
    dispatch(
      updateReportContentState({
        field: {
          ...field,
          latitude,
          longitude,
        },
      })
    );
    const payload = {
      client,
      field: {
        ...field,
        latitude,
        longitude,
      },
    };
    dispatch(setBusy(true));
    const response = await MapService.validateFieldByNameAndClientId(payload);
    if (response.isValid) {
      setEditFieldNameValid(true);
    } else {
      setEditFieldNameValid(false);
      alert(response.reason);
    }
    dispatch(setBusy(false));
    setNewFieldNameValid(true);
  };

  const handleCreationNewField = async () => {
    const fieldNameValid = field.name.length && field.name;
    if (!fieldNameValid) {
      setEditFieldNameValid(false);
      return;
    }
    const isLocationValid = validateLocation(field.location);
    if (!isLocationValid) {
      setEditFieldNameValid(false);
      return;
    }
    const [latitude, longitude] = parseLocation(field.location);
    dispatch(
      updateReportContentState({
        field: {
          ...field,
          latitude,
          longitude,
        },
      })
    );
    const payload = {
      client,
      field: {
        ...field,
        latitude,
        longitude,
      },
    };
    dispatch(setBusy(true));
    const response = await MapService.validateFieldByNameAndClientId(payload);
    if (response.isValid) {
      setEditFieldNameValid(true);
    } else {
      setEditFieldNameValid(false);
      dispatch(setBusy(false));
      alert(response.reason);
      return;
    }
    try {
      const payload = {
        client: client.fullName,
        name: field.name,
        latitude: latitude.trim(),
        longitude: longitude.trim(),
        clientId: client.id,
      };
      const wellCreated = await MapService.updateFieldChanges(payload);
      const res = await MapService.getFullList(true);
      console.log("222222", res);
      console.log(well);
      const fields = res.find((el) => el.fullName === client.fullName).fields;

      const wells = fields.find((el) => el.name === field.name).wells;

      dispatch(updateReportContentState({ clients: res, fields, wells }));
      dispatch(
        updateReportContentState({
          field: { ...field, location: `${field.latitude},${field.longitude}` },
          disableFieldChoose: false,
        })
      );

      setNewFieldInput(false);
      setNewFieldNameValid(false);
    } catch (error) {
      alert(error);
    }
    dispatch(setBusy(false));
  };

  const handleValidationEditField = async () => {
    const fieldNameValid = field.name.length && field.name;
    if (!fieldNameValid) {
      setEditFieldNameValid(false);
      return;
    }
    const isLocationValid = validateLocation(field.location);
    if (!isLocationValid) {
      setEditFieldNameValid(false);
      return;
    }
    const [latitude, longitude] = parseLocation(field.location);
    dispatch(
      updateReportContentState({
        field: {
          ...field,
          latitude,
          longitude,
        },
      })
    );
    const payload = {
      client,
      field: {
        ...field,
        latitude,
        longitude,
      },
    };
    dispatch(setBusy(true));
    const response = await MapService.validateFieldByNameAndClientId(payload);
    if (response.isValid) {
      setEditFieldNameValid(true);
    } else {
      setEditFieldNameValid(false);
      alert(response.reason);
    }
    dispatch(setBusy(false));
  };

  const handleSaveEditField = async () => {
    const fieldNameValid = field.name.length && field.name;
    if (!fieldNameValid) {
      setEditFieldNameValid(false);
      return;
    }
    const isLocationValid = validateLocation(field.location);
    if (!isLocationValid) {
      setEditFieldNameValid(false);
      return;
    }
    const [latitude, longitude] = parseLocation(field.location);
    dispatch(
      updateReportContentState({
        field: {
          ...field,
          latitude,
          longitude,
        },
      })
    );
    const payload = {
      client,
      field: {
        ...field,
      },
    };
    dispatch(setBusy(true));
    const response = await MapService.validateFieldByNameAndClientId(payload);
    if (!response.isValid) {
      setEditFieldNameValid(false);
      alert(response.reason);
      return;
    }
    try {
      const payload = {
        reportId: report.id,
        toState: {
          well,
          field: {
            ...field,
            latitude: latitude.trim(),
            longitude: longitude.trim(),
          },
        },
        fromState: { well: report.well, field: report.well.field },
      };
      const changesCreated = await MapService.updateFieldAndWellChanges(
        payload
      );
      const res = await MapService.getFullList(true);
      console.log("222222", res);
      console.log(well);
      const fields = res.find((el) => el.fullName === client.fullName).fields;

      const wells = fields.find((el) => el.id === field.id).wells;

      const {
        actualWellNumber,
        actualLocation,
        actualFieldLocation,
        actualFieldName,
      } = getCurrentState(report, changes, true);

      dispatch(
        updateReportContentState({
          clients: res,
          fields,
          wells,
          actualFieldLocation,
          field: {
            ...field,
            name: actualFieldName,
            location: actualFieldLocation,
          },
          disableFieldChoose: false,
        })
      );

      const currentRepport = await MapService.getReport(report.id);
      dispatch(updateReportState(currentRepport));

      setNewFieldInput(false);
      setNewFieldNameValid(false);
      setEditFieldNameValid(false);
    } catch (error) {
      alert(error);
    }
    dispatch(setBusy(false));
  };

  const onFieldChange = (selectedName) => {
    if (!selectedName) {
      const fieldsDefault = clients.find(
        (el) => el.fullName === client.fullName
      ).fields;
      const defaultWell = report.well;
      const defaultField = report.well.field;
      console.log("999999", report.well.field);
      const defaultWells = report.well.field.wells;
      const defaultClient = report.well.field.client;
      dispatch(
        updateReportContentState({
          client: defaultClient,
          field: defaultField,
          well: defaultWell,
          wells: defaultWells,
          fields: fieldsDefault,
        })
      );
      return;
    }
    const fieldSelected = fields.find((field) => field.name === selectedName);
    const wellsOptions = fieldSelected.wells;
    dispatch(
      updateReportContentState({
        field: { ...field, ...fieldSelected },
        wells: wellsOptions,
        well: null,
      })
    );
  };
  console.log("fieldChange", well);
  return (
    <div>
      <ComboBox
        onChange={(e) => onFieldChange(e.selectedItem)}
        id="field"
        items={fields.map((el) => el.name)}
        itemToString={(item) => item || ""}
        titleText="Field"
        selectedItem={field?.name || null}
        disabled={disableFieldChoose}
      />
      <Button onClick={handleIntentionToAddNewEntity}>
        {showNewFieldInput ? "hide" : "add new field"}
      </Button>
      <Button onClick={handleIntentionToEditFieldName}>
        {showEditFieldInput ? "hide" : "edit field name"}
      </Button>
      {showNewFieldInput && (
        <div>
          <TextInput
            data-modal-primary-focus
            id="field"
            type="text"
            labelText="Create new field"
            placeholder="Type field"
            style={{
              marginBottom: "1rem",
            }}
            value={field.name}
            onChange={handleTypeNewFieldName}
          />
          <LocationChange isOpen={true} />
          {isNewFieldNameValid ? (
            <Button onClick={handleCreationNewField}>Create</Button>
          ) : (
            <Button onClick={handleValidationNewField}>Validate</Button>
          )}
        </div>
      )}
      {showEditFieldInput && (
        <div>
          <TextInput
            data-modal-primary-focus
            id="field"
            type="text"
            labelText="Edit field name"
            placeholder="Type field"
            style={{
              marginBottom: "1rem",
            }}
            value={field.name}
            onChange={handleTypeEditFieldName}
          />
          <LocationChange isOpen={true} />
          {isEditFieldNameValid ? (
            <Button onClick={handleSaveEditField}>Save</Button>
          ) : (
            <Button onClick={handleValidationEditField}>Validate</Button>
          )}
        </div>
      )}
    </div>
  );
};
