import {
  FormLabel,
  ContentSwitcher,
  Switch,
  TextInput,
  ComboBox,
  Dropdown,
  Button,
  Modal,
  FlexGrid,
  Column,
  Row,
  ModalFooter,
  ModalBody,
} from "@carbon/react";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks.js";
import { setBusyState } from "../../helpers/index.js";
import {
  selectReport,
  updateReportState,
  flushReport,
} from "../../redux/reducers/ReportSlice.js";
import {
  selectReportContent,
  updateReportContentState,
} from "../../redux/reducers/ReportContentSlice.js";

import MapService from "../../API/service.js";
import { useNavigate } from "react-router-dom";

import FieldChange from "./fieldChange";
import LocationChange from "./locationChange.jsx";
import WellNumber from "./wellChange.jsx";

import { getCurrentState } from "./helpers";

export default ({ setShowModal, showModal }) => {
  const dispatch = useAppDispatch();

  const { clients, fields, wells, well, field, client, changes } =
    useAppSelector(selectReportContent);
  const report = useAppSelector(selectReport);

  const onClientChange = (name = "") => {
    if (name) {
      const fields = clients.find((el) => el.fullName === name).fields;

      dispatch(updateReportContentState({ fields }));
    }
  };

  const [allValuesValid, setAllValuesValid] = useState(false);

  useEffect(() => {
    const getFullList = async () => {
      const res = await MapService.getFullList(true);

      const fields = res.find((el) => el.fullName === client.fullName).fields;

      const wells = fields.find((el) => el.name === field.name).wells;

      const {
        actualWellNumber,
        actualLocation,
        actualFieldName,
        actualFieldLocation,
      } = getCurrentState(report, changes, true);
      dispatch(
        updateReportContentState({
          clients: res,
          fields,
          wells,
          well: { ...well, wellNumber: actualWellNumber },
          location: actualLocation,
          field: { ...field, name: actualFieldName },
          fieldLocation: actualFieldLocation,
        })
      );
    };
    getFullList();
  }, []);
  console.log("EDIT PLACEMENT", well);
  return (
    <Modal
      modalHeading="Edit"
      open={showModal}
      primaryButtonText={"Change"}
      secondaryButtonText="Validate"
      onRequestClose={() => {
        setShowModal(false);
      }}
      onRequestSubmit={async () => {
        const currentReport = await MapService.updateReportChanges({
          id: report.id,
          well,
        });
        dispatch(updateReportState(currentReport));
      }}
    >
      {" "}
      <p
        style={{
          marginBottom: "1rem",
        }}
      >
        Set placement
      </p>
      {/* <ComboBox
        onChange={(e) => {
          onClientChange(e.selectedItem);
          // setClient(clients.find((el) => el.fullName === e.selectedItem));
          // setField({ name: "" });
        }}
        id="client"
        items={clients.map((el) => el.fullName)}
        itemToString={(item) => item || ""}
        titleText="Client"
        selectedItem={client?.fullName}
      /> */}
      <FieldChange />
      <WellNumber />
      {/* <LocationChange /> */}
    </Modal>
  );
};
