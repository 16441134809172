import { useState } from "react";
import { useMapEvents } from "react-leaflet";

export default function MapComponent({ defaultZoom, setIconSize, setFieldSize }) {
  const [zoomLevel, setZoomLevel] = useState(defaultZoom); // initial zoom level provided for MapContainer

  const mapEvents = useMapEvents({
    zoomend: () => {
      const currentZoomValue = mapEvents.getZoom();
      const multiplier = currentZoomValue - zoomLevel;

      setIconSize((sizeArr) => sizeArr.map((e) => e + 2 * multiplier));
      setFieldSize((sizeArr) => sizeArr.map((e) => e + 25 * multiplier));
      setZoomLevel(currentZoomValue);
    },
  });

  return null;
}