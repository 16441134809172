import styles from "./Donut.module.css";

export default function Well({ size, name, color }) {

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width:size[0] * 5 }}>
      {size[0] > 12 && <span
        style={{
          textAlign: "center",
          top: "50%",
          fontSize: 16,
          color: "rgb(64, 72, 189)"
        }}
      >
        {name}
      </span>}
      <div style={{width: size[0], height:size[0], borderRadius:"50%", backgroundColor: color}}></div>
    </div>
  );
}
