import styles from "./WellContent.module.css";
import { useState, useEffect } from "react";
import { Button, ComboBox, TextInput } from "@carbon/react";
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from "../../redux/hooks";
import { setBusyState } from "../../helpers/index.js"

import MapService from "../../API/service";

export default function WellContent({ wellId }) {
  const dispatch = useAppDispatch();

  const navigate = useNavigate()

  const [isCoordsValid, setIsCoordsValid] = useState(true);

  const [allValuesValid, setAllValuesValid] = useState(false);


  const [fields, setFields] = useState([])
  const [well, setWell] = useState({
    field: '',
    wellNumber: '',
    location: '',
    latitude: '',
    longitude: ''
  })

  useEffect(() => {
    const getFullList = async () => {
      try {
        const { fields } = await MapService.getFieldsNamesList();

        setFields(fields);
      } catch (error) {
        setBusyState(dispatch, false);
        return
      }
    };
    const getWell = async (id) => {
      try {
        const currentWell = await MapService.getWellById(id);
        if (!currentWell?.field) {
          return
        }
        setWell({ ...currentWell, field: currentWell.field.name, location: `${currentWell.latitude},${currentWell.longitude}` })
      } catch (error) {
        setBusyState(dispatch, false);
        return;
      }

    };
    setBusyState(dispatch, true);
    getFullList();
    if (wellId) {
      getWell(wellId)
    }
    setBusyState(dispatch, false);
  }, []);


  const validateFields = () => {
    const location = well.location
    console.log(location)
    const regExp = /^[-+]?\d{1,2}\.\d+,[-+]?\d{1,2}\.\d+$/
    if (regExp.test(location)) {
      setIsCoordsValid(true)
    } else {
      setIsCoordsValid(false)
      return false
    }

    if (!well.wellNumber || !well.wellNumber.length || well.wellNumber.name === 'null') {
      return false
    }

    if (!well.field || !well.field.length || well.field === 'null') {
      return false
    }
    return true
  }
  const handleDelete = async (wellId) => {
    const result = await MapService.deleteWell(wellId)
    if (result.wellId) {
      navigate('/oilManagement')
      return
    }
    alert("Problem with delete")
  }
  return (
    <div className={styles["report-form"]} >

      <section>
        <TextInput
          value={well.wellNumber}
          id="name"
          type="text"
          labelText="wellNumber"
          onChange={(e) => {
            const value = e.target.value

            setWell({ ...well, wellNumber: value })
            setAllValuesValid(false)
          }}
        />
        <ComboBox
          onChange={(e) => {
            setWell({ ...well, field: e.selectedItem })
            setAllValuesValid(false)
          }}
          id="field"
          items={fields.map((el) => el)}
          itemToString={(item) => item || ""}
          titleText="field"
          selectedItem={well?.field || ''}
        />
        <TextInput
          value={well.location}
          id="location"
          type="text"
          labelText="Location"
          onChange={(e) => {
            const value = e.target.value
            let [latitude, longitude] = e.target.value.split(',')
            if (latitude) {
              latitude = latitude.replace(/ /gi, '')
            }
            if (longitude) {
              longitude = longitude.replace(/ /gi, '')
            }
            setWell({ ...well, location: value, longitude, latitude })
            setAllValuesValid(false)

          }}
          invalid={!isCoordsValid}
          invalidText="Invalid coordinate format, please use the following one **.***,**.***"
        />

        <div className={styles.actions}>
          {well.id && <Button onClick={() => handleDelete(well.id)} kind="danger">Delete</Button>}
          <Button
            onClick={async () => {
              if (allValuesValid) {
                try {
                  setBusyState(dispatch, true);
                  const updatedWell = await MapService.updateWellChanges(well)
                  setWell({ ...updatedWell, field: updatedWell.field.name, location: `${updatedWell.latitude},${updatedWell.longitude}` })
                  setAllValuesValid(false)
                  setBusyState(dispatch, false);
                  navigate(`/well/${updatedWell.id}`)
                } catch (error) {
                  console.log(error.message)
                  alert(error.message)
                  setAllValuesValid(false)
                  setBusyState(dispatch, false);
                }

              } else {
                const isValid = validateFields()
                console.log('isValid', isValid)
                setAllValuesValid(isValid)
              }

            }}
            kind="primary"
          >
            {allValuesValid ? "Save" : "Validate"}
          </Button>
        </div>
      </section>
    </div>

  );
}
