import styles from "./ReportHeader.module.css";
import { Section, Heading, FormLabel } from "@carbon/react";

export default function ReportHeader({ title, description, icon }) {

  return (
    <Section level={4}>
      {icon && <img className={styles.logo} src={icon} />}
      <Heading>{title}</Heading>
      <Section>
        <FormLabel>{description}</FormLabel>
      </Section>
    </Section>
  );
}
