import "./App.css";
import "./app.scss";
import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import LoginPage from "./pages/login";
import ListPage from "./pages/list";
import CreatePage from "./pages/create";
import EditPage from "./pages/edit";
import DeclinedReports from "./pages/declined";
import ReportPage from "./pages/report";
import MapPage from "./pages/map";
import WellReporstPage from "./pages/wellReports";
import OilManagement from './pages/oilManagement'
import FieldPage from './pages/field'
import WellPage from "./pages/well"
import WellState from './pages/wellState'
import ErrorBoundary from "./components/ErrorBoundry/ErrorBoundry";
import { ThreeDots } from 'react-loader-spinner';
import { useAppSelector } from "./redux/hooks";
import { getBusy } from "./redux/reducers/BusySlice";

export default function App() {
  const navigate = useNavigate();
  const role = JSON.parse(localStorage.getItem("tokens"))?.user?.role || "";
  const busy = useAppSelector(getBusy)

  useEffect(() => {
    const creds = localStorage.getItem("creds");

    if (!creds) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<ListPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/report/:id" element={<ReportPage />} />
          <Route path="/well-state/:id" element={<WellState />} />
          <Route path="/map" element={<MapPage />} />
          {role === "wellHeadSuperVisor" && <Route path="/newReport" element={<CreatePage />} />}
          {role === "wellHeadSuperVisor" && <Route path="/editReport/:id" element={<EditPage />} />}
          {role !== "client" && <Route path="/declinedReports" element={<DeclinedReports />} />}
          {role === "client" && <Route path="/reports/:id" element={<WellReporstPage />} />}
          {/* {role === "engineerOnCharge" && <Route path="/oilManagement" element={<OilManagement />} />} */}
          {role === "engineerOnCharge" && <Route path="/newWell" element={<WellPage />} />}
          {role === "engineerOnCharge" && <Route path="/newField" element={<FieldPage />} />}
          {role === "engineerOnCharge" && <Route path="/well/:id" element={<WellPage />} />}
          {role === "engineerOnCharge" && <Route path="/field/:id" element={<FieldPage />} />}

        </Routes>
        {busy && <div className="busy"><ThreeDots color="#1690db" /></div>}
      </ErrorBoundary>
    </>
  );
}
