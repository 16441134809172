import styles from "./FirstStep.module.css";
import { FormLabel, ContentSwitcher, Switch, TextInput, ComboBox, Dropdown, Button, Modal, FlexGrid, Column, Row } from "@carbon/react";
import { OPERATION, WELL_HEAD_SIZE } from "../../data/fields";
import { Add } from "@carbon/icons-react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectReport, updateReportState } from "../../redux/reducers/ReportSlice";
import { useEffect, useState } from "react";
import MapService from "../../API/service";

import AddWell from './addWell'
import EditLocation from './editLocation'

export default function FirstStep() {
  const [isCoordsValid, setIsCoordsValid] = useState(true);
  const [showWellModal, setShowWellModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [clients, setClients] = useState([]);
  const [fields, setFields] = useState([]);
  const [wells, setWells] = useState([]);

  const dispatch = useAppDispatch();
  const report = useAppSelector(selectReport);




  const onFieldChange = (fieldName, value = "", regExp, setFunc) => {
    if (regExp && regExp.test(value)) {
      setFunc(regExp.test(value));
    } else if (regExp && !regExp.test(value)) {
      setFunc(regExp.test(value));

      return;
    }

    dispatch(updateReportState({ [fieldName]: value }));
  };
  const onClientChange = (name = "") => {
    if (name) {
      const fields = clients.find((el) => el.fullName === name).fields;

      setFields(fields);
    }
    if (name !== report.client) {
      dispatch(updateReportState({ field: "", wellNumber: "", location: "" }));
    }
  };
  const onFieldsFieldChange = (name) => {
    if (name) {
      const wells = fields.find((el) => el.name === name).wells;

      setWells(wells);
    }
    if (name !== report.field) {
      dispatch(updateReportState({ wellNumber: "", location: "" }));
    }
  };
  const onWellChange = (name) => {
    if (name) {
      const data = wells.find((el) => el.wellNumber === name);

      dispatch(updateReportState({ location: `${data.latitude}, ${data.longitude}` }));
    }
  };
  const resetAllValuesAfterAddWell = (clients, clientId, field) => {
    setClients(clients);
    console.log(clients, clientId, field)
    const fields = clients.find((el) => el.id === clientId).fields;

    setFields(fields);

    const wells = fields.find((el) => el.name === field).wells;

    setWells(wells);
  };

  const resetWellAfterChangeLocation = (data) => {
    dispatch(updateReportState({ longitude: data.longitude, latitude: data.latitude, location: `${data.latitude}, ${data.longitude}` }));
    console.log('finish')
    console.log(report)
  }

  useEffect(() => {
    const getFullList = async () => {
      const res = await MapService.getFullList(true);

      setClients(res);
    };
    getFullList();
  }, []);
  console.log('-------', report)
  return (
    <>
      {showWellModal && <AddWell report={report} setShowWellModal={setShowWellModal} showModal={showWellModal} resetAllValuesAfterAddWell={resetAllValuesAfterAddWell} />}
      {showLocationModal && <EditLocation report={report} setShowLocationModal={setShowLocationModal} showModal={showLocationModal} resetAllValuesAfterEditLocation={resetWellAfterChangeLocation} oldWell={wells.find((el) => el.wellNumber === report.wellNumber)} />}
      {/* <div className={styles["radio-div"]}>
        <FormLabel>Well complience</FormLabel>
        <ContentSwitcher
          selectedIndex={["Yes", "No"].findIndex((v) => v === report.wellCompliance)}
          className={styles.switcher}
          onChange={(e) => {
            onFieldChange("wellCompliance", e.name);
          }}
        >
          <Switch name={"Yes"} text="Yes" />
          <Switch name={"No"} text="No" />
        </ContentSwitcher>
      </div> */}
      <ComboBox
        onChange={(e) => {
          onFieldChange("client", e.selectedItem);
          onClientChange(e.selectedItem);
        }}
        id="client"
        items={clients.map((el) => el.fullName)}
        itemToString={(item) => item || ""}
        titleText="Client"
        selectedItem={report.well?.field?.client?.fullName || report.client}
        disabled={!!report.id}
      />

      <ComboBox
        onChange={(e) => {
          onFieldChange("field", e.selectedItem);
          onFieldsFieldChange(e.selectedItem);
        }}
        id="field"
        items={fields.map((el) => el.name)}
        itemToString={(item) => item || ""}
        titleText="Field"
        selectedItem={report.well?.field?.name || report.field}
        disabled={!!report.id}
      />
      <FlexGrid>
        <Row>
          <Column sm={10} md={10} lg={10}>
            <ComboBox
              onChange={(e) => {
                onFieldChange("wellNumber", e.selectedItem);
                onWellChange(e.selectedItem);
              }}
              id="wellNumber"
              items={wells.map((el) => el.wellNumber)}
              itemToString={(item) => item || ""}
              titleText="Well number"
              selectedItem={report.well?.wellNumber || report.wellNumber}
              //value={report.field ? report.field : ""}
              disabled={!!report.id}
            />
          </Column>
          <Column sm={2} md={2} lg={2}>
            <Button onClick={() => {
              setShowWellModal(true);
            }} kind="primary" tabIndex={0} size="sm" type="button" role="button" disabled={!report.field}>
              new
            </Button>
          </Column>
        </Row>
      </FlexGrid>
      <div className={styles["flex-content"]} style={{ alignItems: "start" }}>
        <FlexGrid>
          <Row>
            <Column>  <TextInput
              value={report.id ? `${report.well.latitude}, ${report.well.longitude}` : report.location}
              id="location"
              type="text"
              labelText="Location"
              onChange={(e) => {
                onFieldChange("location", e.target.value, /^[-+]?\d{1,3}\.\d+, [-+]?\d{1,3}\.\d+$/, setIsCoordsValid);
              }}
              invalid={!isCoordsValid}
              invalidText="Invalid coordinate format, please use the following one ***.***, ***.***"
              disabled={true}//!!report.id}
            /></Column>
            <Column>
              <Button onClick={() => {
                setShowLocationModal(true);
              }} kind="primary" tabIndex={0} size="sm" type="button" role="button" disabled={!report.field}>
                edit
              </Button></Column>
          </Row>
        </FlexGrid>

        {/* <Button
          kind="secondary"
          renderIcon={Add}
          style={{ marginTop: "16px" }}
          onClick={() => {
            setShowModal(true);
          }}
        >
          Add location
        </Button> */}
      </div>
      <div className={styles["flex-content"]}>
        <Dropdown
          label=""
          className={styles["flex-item"]}
          onChange={(e) => {
            onFieldChange("wellHeadSize", e.selectedItem);
          }}
          id="headSize"
          items={WELL_HEAD_SIZE}
          itemToString={(item) => item || ""}
          titleText="Well head size"
          selectedItem={report.wellHeadSize}
        />
        {/* <TextInput
          className={styles["flex-item"]}
          disabled
          id="visits"
          value={report.numVisit ? report.numVisit : 1}
          type="text"
          labelText="Visits"
        /> */}
      </div>
      <div className={styles["radio-div"]}>
        <FormLabel>Rig number</FormLabel>
        <ContentSwitcher
          className={styles.switcher}
          selectedIndex={report.operations !== "Rigless" ? 1 : 0}
          onChange={(e) => {
            onFieldChange("operations", e.name);
            // setRigless(e.name === "Rigless");
          }}
        >
          <Switch name={"Rigless"} text="Rigless" />
          <Switch name={"Rig Number"} text="RigNumber" />
        </ContentSwitcher>
      </div>
      <TextInput
        value={report.operations !== "Rigless" ? report.operations : ''}
        id="operation_string"
        type="text"
        labelText="Rig Number"
        onChange={(e) => {
          onFieldChange("operations", e.target.value);
        }}
        invalid={!isCoordsValid}
        invalidText="Invalid Rig Number format"
        disabled={report.operations === "Rigless"}
      />
    </>
  );
}
