import { useState } from "react";
import {
  FormLabel,
  ContentSwitcher,
  Switch,
  TextInput,
  ComboBox,
  Dropdown,
  Button,
  Modal,
  FlexGrid,
  Column,
  Row,
  ModalFooter,
  ModalBody,
} from "@carbon/react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks.js";
import { setBusyState } from "../../helpers/index.js";
// import {
//   selectReport,
//   updateReportState,
//   flushReport,
// } from "../../redux/reducers/ReportSlice.js";
import {
  selectReport,
  updateReportState,
  flushReport,
} from "../../redux/reducers/ReportSlice.js";
import {
  selectReportContent,
  updateReportContentState,
} from "../../redux/reducers/ReportContentSlice.js";

import { validateLocation, parseLocation } from "./helpers";

export default ({ isOpen = false }) => {
  const dispatch = useAppDispatch();
  const { well } = useAppSelector(selectReportContent);
  const report = useAppSelector(selectReport);

  const [oldLocation] = useState(`${well.latitude},${well.longitude}`);
  const [showEditLocationInput, setShowEditLocationInput] = useState(isOpen);
  const [isCoordsValid, setIsCoordsValid] = useState(true);

  const handleShowEditLocationInput = () => {
    setShowEditLocationInput(!showEditLocationInput);
    dispatch(
      updateReportContentState({
        well: {
          ...well,
          latitude: report.well.latitude,
          longitude: report.well.longitude,
          location: `${report.well.latitude.trim()},${report.well.longitude.trim()}`,
        },
      })
    );
  };

  const handleValidateLocation = () => {
    const result = validateLocation(well.location);
    setIsCoordsValid(result);
  };

  const handleSaveLocation = () => {
    const isLastCordValid = validateLocation(well.location);
    if (isLastCordValid) {
      const [latitude, longitude] = parseLocation(well.location);
      dispatch(
        updateReportContentState({
          well: {
            ...well,
            location: `${latitude.trim()},${longitude.trim()}`,
            latitude,
            longitude,
          },
        })
      );
      setIsCoordsValid(true);
    } else {
      setIsCoordsValid(false);
    }
  };
  console.log("locationChange", isCoordsValid);
  return (
    <div>
      <br />
      <div>
        {" "}
        <span>Location {oldLocation}</span>
      </div>

      {!isOpen && (
        <Button onClick={handleShowEditLocationInput}>
          {showEditLocationInput ? "Hide" : "Edit location"}
        </Button>
      )}
      {showEditLocationInput && (
        <div>
          <TextInput
            data-modal-primary-focus
            id="location"
            type="text"
            labelText="Location (latitude,longitude)"
            placeholder="Type location"
            style={{
              marginBottom: "1rem",
            }}
            value={well.location}
            onChange={(e) => {
              const value = e.target.value;
              dispatch(
                updateReportContentState({
                  well: { ...well, location: value },
                })
              );
            }}
            invalid={!isCoordsValid}
            invalidText="Invalid coordinate format, please use the following one **.***,**.***"
          />
          {!isOpen ? (
            isCoordsValid ? (
              <Button onClick={handleSaveLocation}>Save</Button>
            ) : (
              <Button onClick={handleValidateLocation}>Validate</Button>
            )
          ) : null}
        </div>
      )}
    </div>
  );
};
