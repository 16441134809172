import { configureStore } from '@reduxjs/toolkit';
import stepperReducer from './reducers/StepperSlice'
import reportReducer from './reducers/ReportSlice'
import mapReducer from './reducers/MapSlice'
import busyReducer from './reducers/BusySlice'
import reportContentReducer from './reducers/ReportContentSlice'
import WellWithStatus from './reducers/WellWithStatus'
import FieldWithStatus from './reducers/FieldWithStatus'
import AppHeader from './reducers/AppHeader'

export const store = configureStore({
    reducer: {
        stepper: stepperReducer,
        report: reportReducer,
        map: mapReducer,
        busy: busyReducer,
        reportContent: reportContentReducer,
        wellWithStatus: WellWithStatus,
        fieldWithStatus: FieldWithStatus,
        appHeader: AppHeader
    },
});