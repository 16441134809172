import {
  FormLabel,
  ContentSwitcher,
  Switch,
  TextInput,
  ComboBox,
  Dropdown,
  Button,
  Modal,
  FlexGrid,
  Column,
  Row,
  ModalFooter,
  ModalBody,
} from "@carbon/react";
import { useEffect, useState } from "react";

import { useAppDispatch } from "../../redux/hooks";
import { setBusyState } from "../../helpers/index.js";

import MapService from "../../API/service";
import { useNavigate } from "react-router-dom";

export default ({
  report,
  setShowWellModal,
  showModal,
  resetAllValuesAfterAddWell,
}) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [well, setWell] = useState({
    field: "",
    wellNumber: "",
    location: "",
    latitude: "",
    longitude: "",
  });

  const flushState = () =>
    setWell({
      field: "",
      wellNumber: "",
      location: "",
      latitude: "",
      longitude: "",
    });

  const [isCoordsValid, setIsCoordsValid] = useState(false);
  const [isNameValid, setIsNameValid] = useState(false);
  const [isFieldValid, setIsFieldValid] = useState(false);
  const [allValuesValid, setAllValuesValid] = useState(false);

  useEffect(() => {
    if (!report.field) {
      alert("NEED FIELD");
    } else {
      setWell({ ...well, field: report.field });
    }
  }, []);

  const validateFields = () => {
    const location = well.location;
    console.log(location);
    /**
     * Объяснение:
      ^: Начало строки.
      [-+]?: Опциональный знак - или +.
      \d{1,2}: Одна или две цифры (градусы широты).
      \.: Точка.
      \d+: Одна или более цифр (дробная часть широты).
      ,: Запятая.
      *: Ноль или более пробелов.
      [-+]?: Опциональный знак - или +.
      \d{1,3}: Одна, две или три цифры (градусы долготы, чтобы учитывать до 180).
      \.: Точка.
      \d+: Одна или более цифр (дробная часть долготы).
      $: Конец строки.
      Теперь регулярное выражение позволяет наличие пробелов после запятой. Примеры, которые соответствуют:

      40.741895,-73.989308
      40.741895, -73.989308
      40.741895, -73.989308
      40.741895, 73.989308
      -40.741895, 73.989308
      40.741895,73.989308
      -40.741895,-73.989308
      Это регулярное выражение корректно обрабатывает наличие пробелов после запятой.
     */
    const regExp = /^[-+]?\d{1,2}\.\d+,[-+]?\d{1,2}\.\d+$/;
    const regExpSpace = /^[-+]?\d{1,2}\.\d+, *[-+]?\d{1,3}\.\d+$/;
    if (regExp.test(location) || regExpSpace.test(location)) {
      setIsCoordsValid(true);
    } else {
      setIsCoordsValid(false);
      return false;
    }

    if (
      !well.wellNumber ||
      !well.wellNumber.length ||
      well.wellNumber === "null"
    ) {
      setIsNameValid(false);
      return false;
    } else {
      setIsNameValid(true);
    }

    if (!well.field || !well.field.length || well.field === "null") {
      setIsFieldValid(false);
      return false;
    } else {
      setIsFieldValid(true);
    }

    setAllValuesValid(true);
    return true;
  };
  console.log(well);
  return (
    <Modal
      modalHeading="Add Well"
      open={showModal}
      primaryButtonText={allValuesValid ? "Create" : "Can't"}
      secondaryButtonText="Validate"
      onRequestClose={() => {
        setShowWellModal(false);
      }}
      onRequestSubmit={async () => {
        if (allValuesValid) {
          try {
            console.log("++++++++++");
            setBusyState(dispatch, true);
            const createdWell = await MapService.createWellChanges(well);
            setWell({
              ...well,
              field: well.field,
              location: `${well.latitude},${well.longitude}`,
            });

            const clients = await MapService.getFullList(true);

            setBusyState(dispatch, false);
            setShowWellModal(false);
            resetAllValuesAfterAddWell(
              clients,
              createdWell.field.clientId,
              createdWell.field.name,
              createdWell.wellNumber
            );
            flushState();
          } catch (error) {
            console.log(error.message);
            alert(error.message);
            setAllValuesValid(false);
            setBusyState(dispatch, false);
          }
        } else {
          const isValid = validateFields();
          console.log("isValid", isValid);
          setAllValuesValid(isValid);
        }
      }}
      onSecondarySubmit={() => {
        const isValid = validateFields();
        setAllValuesValid(isValid);
      }}
    >
      <ModalBody hasScrollingContent>
        <p
          style={{
            marginBottom: "1rem",
          }}
        >
          Add new Well {well.wellNumber} with Location {well.location} to{" "}
          {well.field}
        </p>
        <TextInput
          data-modal-primary-focus
          id="addLocationInput"
          labelText="Well number"
          placeholder="Type Well number"
          style={{
            marginBottom: "1rem",
          }}
          onChange={(e) => {
            const value = e.target.value;

            setWell({ ...well, wellNumber: value });
            setAllValuesValid(false);
          }}
          value={well.wellNumber}
          invalid={!isNameValid}
          invalidText="Invalid Well Number, Type well number"
        />
        <TextInput
          data-modal-primary-focus
          id="location"
          type="text"
          labelText="Location (latitude,longitude)"
          placeholder="Type location"
          style={{
            marginBottom: "1rem",
          }}
          onChange={(e) => {
            console.log("------");
            const value = e.target.value;
            let [latitude, longitude] = e.target.value.split(",");
            if (latitude) {
              latitude = latitude.replace(/ /gi, "");
            }
            if (longitude) {
              longitude = longitude.replace(/ /gi, "");
            }
            setWell({ ...well, location: value, longitude, latitude });
            setAllValuesValid(false);
          }}
          invalid={!isCoordsValid}
          invalidText="Invalid coordinate format, please use the following one **.***,**.***"
        />
      </ModalBody>

      {/* <ModalFooter>
        <Button kind="secondary" onClick={() => {}}>
          Secondary button
        </Button>
      </ModalFooter> */}
    </Modal>
  );
};
