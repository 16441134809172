import React, { useState, useEffect } from "react";
import AppHeader from "../components/Header/AppHeader";
import ReportForm from "../components/ReportForm/ReportForm";
import ReportHeader from "../components/ReportHeader/ReportHeader";
import ReportContent from "../components/ReportContent/ReportContent";
import MapService from "../API/service";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { setBusy } from "../redux/reducers/BusySlice";
import { selectReport, updateReportState, flushReport } from "../redux/reducers/ReportSlice";


export default function WellState() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [wellStateSubmittedAndFresh, setWellState] = useState(null);
  const [isFetched, setFetched] = useState(false);



  //approveDate
  const targetStatus = "Submitted"

  useEffect(() => {
    const getReportsByWellId = async (id) => {
      try {
        setFetched(true)
        dispatch(flushReport)
        const result = await MapService.getReportsByWellId(id, 1, 100000);
        const submitted = result.items.filter(report => report.status == targetStatus)
        const attachments = submitted.reduce((acc, report) => acc.concat(report.attachments), [])
        submitted.sort((a, b) => b - a)

        //GET ONLY LAST REPORT!!!
        const report = submitted.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]
        if (report) {
          report.attachments = attachments
          setWellState(report)
          dispatch(updateReportState(report))
        }
        dispatch(setBusy(false));
      } catch (error) {
        dispatch(setBusy(false));
      }
    };

    dispatch(setBusy(true));
    getReportsByWellId(id);
  }, []);
  console.log(wellStateSubmittedAndFresh);
  return (
    <section className="report-section">
      <AppHeader />
      {isFetched && !wellStateSubmittedAndFresh && "No reports"}
      {wellStateSubmittedAndFresh && <ReportForm
        header={
          <ReportHeader
            title="Well integrity report"
            description={wellStateSubmittedAndFresh.createdAt ? moment(new Date(wellStateSubmittedAndFresh.createdAt)).format("DD.MMM.YYYY") : ""}
            iconUrl={wellStateSubmittedAndFresh?.well?.field?.client?.iconUrl}
          />
        }
        content={
          <ReportContent
            onActionClick={() => { }}
            report={wellStateSubmittedAndFresh}
            onFieldChange={() => { }}
            submitChanges={() => { }}
          />
        }
      />}

    </section>
  );
}
