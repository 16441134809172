import AppHeader from "../components/Header/AppHeader";
import Map from "../components/Map/Map";
import MapService from "../API/service";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateMapState } from "../redux/reducers/MapSlice";
import { useAppDispatch } from "../redux/hooks";
import { setBusyState } from "../helpers/index.js";
import { MAP_DEFAULT_CENTER, MAP_DEFAULT_ZOOM, MAP_DEFAULT_MAX_BOUNDS } from "../data/constants.js";

export default function MapPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mode = window.location.hash.slice(1).split("=")[0];

  useEffect(() => {
    const getMapInfo = async () => {
      const me = await MapService.getMe();
      const resp = await MapService.getMapInfo();
      const client = me.wellHeadSuperVisor || me.engineerOnCharge ? {
        shortCut: "NOC"
      } : me.employee.client;

      dispatch(updateMapState({ fields: resp, me: me, fieldsDefault: resp }));
      setBusyState(dispatch, false);

      if (client.shortCut === "NOC" && mode === "") {
        navigate("#clients")
      }
      if (client.shortCut !== "NOC" && mode === "") {
        navigate("#fields=" + client.id)
      }
    };

    try {
      setBusyState(dispatch, true);
      getMapInfo();
    } catch (error) {
      setBusyState(dispatch, false);
    }
  }, []);

  return (
    <section className="map-section">
      <AppHeader />
      <section className="map-content">
        <Map center={MAP_DEFAULT_CENTER} zoom={MAP_DEFAULT_ZOOM} bounds={MAP_DEFAULT_MAX_BOUNDS} />
      </section>
    </section>
  );
}
