import styles from "./Header.module.css";
import { Logout } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";
import {
  Header,
  HeaderGlobalAction,
  HeaderGlobalBar,
  Theme,
  HeaderNavigation,
  HeaderMenuItem,
  MenuItem,
  Menu,
  Tag,
} from "@carbon/react";
import { useLocation } from "react-router-dom";
import logo from "./lg.png";
import noc from "./noc.png";

import { selectMap } from "../../redux/reducers/MapSlice";
import { selectWellsWithState, updateWellsStatusState } from '../../redux/reducers/WellWithStatus'
import { selectFieldsWithState, updateFieldsStatusState } from '../../redux/reducers/FieldWithStatus'
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { selectAppHeader, updateModal } from '../../redux/reducers/AppHeader'

import Logo from "../Logo/Logo";
import { useEffect, useState } from "react";
import MapService from "../../API/service";
import { ROLES } from "../../data/constants";

import CrucialModal from './crucialModal'

export default function AppHeader({ navigation }) {
  const { pathname } = useLocation();
  const role = JSON.parse(localStorage.getItem("tokens"))?.user?.role;
  const navigate = useNavigate();
  const declinedReportsPath = "/declinedReports";
  const mapState = useAppSelector(selectMap);

  const dispatch = useAppDispatch()
  const wellsWithState = useAppSelector(selectWellsWithState);
  const fieldWithState = useAppSelector(selectFieldsWithState);
  const appHeaderState = useAppSelector(selectAppHeader);

  const [me, setMe] = useState({});
  let clientUrl = mapState.fields?.[0]?.client?.iconUrl;
  let headerNavigation;

  useEffect(() => {
    const getClientLogo = async () => {
      const resp = await MapService.getMe();

      setMe(resp.user);
      clientUrl = resp?.employee?.client?.iconUrl;
      if (!resp.employee) {
        const { fields, wells } = await MapService.getWellsByStatus("Unapproved")
        dispatch(updateFieldsStatusState({ unapproved: fields }))
        dispatch(updateWellsStatusState({ unapproved: wells }))
      }

    };

    getClientLogo();
  }, []);

  switch (pathname) {
    case "/newReport":
      headerNavigation = (
        <HeaderNavigation aria-label="IBM [Platform]">
          <HeaderMenuItem className={styles.underscored} href="/">
            My reports
          </HeaderMenuItem>
          {/* <HeaderMenuItem href="/history">
            History{" "}
            <Tag className={styles.tag} type="blue">
              12
            </Tag>
          </HeaderMenuItem> */}
        </HeaderNavigation>
      );
      break;
    case "/history":
      headerNavigation = (
        <HeaderNavigation aria-label="IBM [Platform]">
          <HeaderMenuItem href="/">My reports</HeaderMenuItem>
          <HeaderMenuItem className={styles.underscored} href="/history">
            History{" "}
          </HeaderMenuItem>
        </HeaderNavigation>
      );
      break;
    case "/map":
      headerNavigation = (
        <HeaderNavigation aria-label="IBM [Platform]">
          <HeaderMenuItem className={styles.underscored} href="/map">
            Map
          </HeaderMenuItem>
          {role !== "client" && <HeaderMenuItem href="/">Reports</HeaderMenuItem>}
        </HeaderNavigation>
      );
      break;
    default:
      const isShowUnApprovedWells = wellsWithState.unapproved.length ? true : false
      const isShowUnApprovedFields = fieldWithState.unapproved.length ? true : false

      headerNavigation = (
        <HeaderNavigation aria-label="IBM [Platform]">
          {/* <HeaderMenuItem className={pathname === "/" || "" ? styles.underscored : ""} href="/">
            Reports
          </HeaderMenuItem> */}
          <HeaderMenuItem className={pathname === "/map" || "" ? styles.underscored : ""} href="/map">
            Map
          </HeaderMenuItem>
          {(isShowUnApprovedWells || isShowUnApprovedFields) && role === "engineerOnCharge" && (<HeaderMenuItem onClick={() => dispatch(updateModal(true))}>
            Crucial changes
            <Tag size="md" type="red">
              {wellsWithState.unapproved.length + fieldWithState.unapproved.length}
            </Tag>
            {appHeaderState.showModalWithChanges && <CrucialModal />}
          </HeaderMenuItem>)}
          {/* {role === "wellHeadSuperVisor" && (
            <HeaderMenuItem
              className={pathname === declinedReportsPath ? styles.underscored : ""}
              href="/declinedReports"
            >
              Declined reports{" "}
              <Tag className={styles.tag} type="red">
                12
              </Tag>
            </HeaderMenuItem>
          )} */}
        </HeaderNavigation>
      );
      if (role === "client") {
        headerNavigation = (
          <HeaderNavigation aria-label="IBM [Platform]">
            <HeaderMenuItem className={pathname === "/map" || "" ? styles.underscored : ""} href="/map">
              Map
            </HeaderMenuItem>
            {/* <HeaderMenuItem className={pathname === "/" || "" ? styles.underscored : ""} href="/">
              Reports
            </HeaderMenuItem> */}
          </HeaderNavigation>
        );
      }
      break;
  }

  return (
    <header className={styles.header}>
      <Theme>
        <Header aria-label="GGOFS">
          <div className={styles["logo-div"]}>
            <img className={styles.logo} src={noc} />
            {/* {role === "client" && clientUrl && <img className={styles["client-logo"]} src={clientUrl} />} */}
          </div>
          {headerNavigation}
          <div className={styles["role-div"]}>
            {`${ROLES[me.role]}: ${me.email}`}
          </div>
          <HeaderGlobalBar>
            <Logo style={{ zoom: "0.5", marginTop: "15px" }} />
            <HeaderGlobalAction
              aria-label="Log out"
              onClick={(e) => {
                localStorage.clear();
                navigate("/login");
              }}
            >
              <Logout style={{ maxWidth: "50px", width: "30px", height: "20px" }} />
            </HeaderGlobalAction>
          </HeaderGlobalBar>
        </Header>
      </Theme>
    </header>
  );
}
