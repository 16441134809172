import { useState } from "react";
import styles from "./ReportsTable.module.css";
import { useNavigate } from "react-router-dom";
import {
  DataTable,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Button,
  Pagination,
} from "@carbon/react";
import { Add } from "@carbon/icons-react";
import { STATUSES } from "../../data/constants";
import { useAppDispatch } from "../../redux/hooks";
import { updateToDefault } from "../../redux/reducers/ReportSlice";

export default function ReportsTable({
  title,
  rows,
  page,
  pageSize,
  totalItems,
  headerFields,
  disableAdd,
  usePagination = true,
  showToolbar = true,
  noMargin = false,
  onPageChange,
  onRowClick,
  onSearch
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onAddPress = () => {
    dispatch(updateToDefault());

    navigate("/newReport");
  };
  const onCellClick = (id) => {
    onRowClick(id);
  };
  const headers = headerFields || [];

  return (
    <div className={noMargin ? "" : styles.reports}>
      <DataTable rows={rows} headers={headers}>
        {({ rows, headers, getHeaderProps, getRowProps, getTableProps, onInputChange }) => (
          <TableContainer title={title}>
            {showToolbar && (
              <TableToolbar>
                <TableToolbarContent className={styles["toolbar-content"]}>
                  <TableToolbarSearch onChange={onSearch ||onInputChange} />
                  {!disableAdd && (
                    <Button renderIcon={Add} className={styles.add} onClick={onAddPress} kind="primary">
                      Add new report
                    </Button>
                  )}
                </TableToolbarContent>
              </TableToolbar>
            )}

            <Table {...getTableProps()} aria-label="sample table">
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({
                        header,
                      })}
                    >
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.id}
                    {...getRowProps({
                      row,
                    })}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          onClick={() => {
                            onCellClick(row.id);
                          }}
                          key={cell.id}
                        >
                          {cell.info.header === "status" ? (
                            <span className={styles[STATUSES[cell.value]]}>{cell.value}</span>
                          ) : cell.info.header === "term" ? (
                            <span className={cell.value > 6 ? styles.tnormal : styles.tdanger}>{cell.value}</span>
                          ) : (
                            cell.value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {usePagination && (
              <Pagination
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                page={page}
                pageNumberText="Page Number"
                pageSize={pageSize}
                pageSizes={[pageSize]}
                totalItems={totalItems}
                onChange={onPageChange}
              />
            )}
          </TableContainer>
        )}
      </DataTable>
    </div>
  );
}
