import React, { useState, useEffect } from "react";
import AppHeader from "../components/Header/AppHeader";
import ReportsTable from "../components/ReportsTable/ReportsTable";
import MapService from "../API/service";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { LIST_COLUMNS } from "../data/constants";
import { setBusyState } from "../helpers/index.js"

export default function List() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [reports, setReports] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const headers = LIST_COLUMNS;
  const defaultFilter = null;
  const role = JSON.parse(localStorage.getItem("tokens"))?.user?.role;
  const onPageChange = async (e) => {
    const result = search
      ? await MapService.searchReports(search, e.page, pageSize)
      : await MapService.getReports(e.page, pageSize);

    setPage(+e.page);
    setReports(result.items);
  };
  const onRowClick = (id) => {
    navigate(`/report/${id}`);
  };
  const onSearch = async (e) => {
    setBusyState(dispatch, true);
    const { value } = e.target;
    const resp = await MapService.searchReports(value, 1, pageSize, defaultFilter);

    setSearch(value);
    setReports(resp.items);
    setTotalItems(resp.totalItems);
    setPage(+resp.page);
    setBusyState(dispatch, false);
  };

  const items = reports.map((report) => ({
    id: report.id,
    well: report.well.wellNumber,
    field: report.well.field.name,
    client: report.well.field.client.shortCut,
    createdDate: moment(report.createdAt).format("DD.MMM.YYYY"),
    reviewedBy: report.rejectedBy
      ? report.rejectedBy.user.username
      : report.approvedBy
        ? report.approvedBy.user.username
        : "Not reviewed yet",
    createdBy: report.createdBy.user.username,
    status: report.status,
  }));

  useEffect(() => {
    async function getData() {
      try {
        const result = await MapService.getReports(page, pageSize, defaultFilter);

        setReports(result.items);
        setPage(+result.page);
        setTotalItems(result.totalItems);
        setBusyState(dispatch, false);
      } catch (error) {
        setBusyState(dispatch, false);
        return;
      }
    }
    setBusyState(dispatch, true);
    getData();
  }, []);

  return (
    <section className="list-section">
      <AppHeader />
      <ReportsTable
        onSearch={onSearch}
        title="Reports"
        rows={items}
        headerFields={headers}
        totalItems={totalItems}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onRowClick={onRowClick}
        disableAdd={role !== "wellHeadSuperVisor"}
      />
    </section>
  );
}
