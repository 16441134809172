import AppHeader from "../components/Header/AppHeader";
import ReportForm from "../components/ReportForm/ReportForm";
import ReportHeader from "../components/ReportHeader/ReportHeader";
import ReportContent from "../components/ReportContent/ReportContent";
import MapService from "../API/service";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectReport, updateReportState } from "../redux/reducers/ReportSlice";

import FieldContent from '../components/FieldContent/FieldContent'

export default function FieldPage() {
    const { id } = useParams();
    // console.log(report);
    return (
        <section className="report-section">
            <AppHeader />
            <FieldContent fieldId={id} />
        </section>
    );
}
