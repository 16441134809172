import React, { useState, useEffect } from "react";
import AppHeader from "../components/Header/AppHeader";
import ReportsTable from "../components/ReportsTable/ReportsTable";
import MapService from "../API/service";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { setBusy } from "../redux/reducers/BusySlice";

export default function List() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [reports, setReports] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const headers = [
    {
      key: "well",
      header: "Well",
    },
    {
      key: "field",
      header: "Field",
    },
    {
      key: "createdDate",
      header: "Created Date",
    },
    {
      key: "status",
      header: "Status",
    },
  ];
  const items = reports.map((el) => ({
    id: el.id,
    well: el.well.wellNumber,
    field: el.well.field.name,
    createdDate: moment(el.createdAt).format("DD.MMM.YYYY"),
    status: el.status,
  }));
  const onRowClick = (id) => {
    navigate(`/report/${id}`);
  };

  useEffect(() => {
    const getReportsByWellId = async (id) => {
      try {
        const result = await MapService.getReportsByWellId(id, page, pageSize);

        setReports(result.items);
        setPage(+result.page);
        //setPageSize(+result.pageSize)
        setTotalItems(result.totalItems);
        dispatch(setBusy(false));
      } catch (error) {
        dispatch(setBusy(false));
      }
    };

    dispatch(setBusy(true));
    getReportsByWellId(id);
  }, []);

  return (
    <section className="list-section">
      <AppHeader />
      <ReportsTable
        title="Reports"
        rows={items}
        headerFields={headers}
        totalItems={totalItems}
        page={page}
        pageSize={pageSize}
        // onPageChange={onPageChange}
        onRowClick={onRowClick}
        disableAdd={true}
      />
    </section>
  );
}
