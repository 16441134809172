import styles from "./Stepper.module.css";
import FirstStep from "../FirstStep/FirstStep";
import SecondStep from "../SecondStep/SecondStep";
import ThirdStep from "../ThirdStep/ThirdStep";
import FourthStep from "../FourthStep/FourthStep";
import MapService from "../../API/service";
import { useNavigate } from "react-router-dom";
import { ProgressIndicator, ProgressStep, Button } from "@carbon/react";
import { selectCurrentStep, setCurrentStep, selectStepperMode } from "../../redux/reducers/StepperSlice"
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectReport, updateReportState } from '../../redux/reducers/ReportSlice'

export default function Stepper() {
  const dispatch = useAppDispatch();
  const currentStep = useAppSelector(selectCurrentStep);
  const report = useAppSelector(selectReport);
  const isEditMode = useAppSelector(selectStepperMode);

  const navigate = useNavigate();
  const createReport = async (report) => {
    return MapService.createNewReport(report);
  }
  const updateReport = async (report) => {
    return await MapService.updateReport(report);
  }

  const onProgressChange = (number) => {
    dispatch(setCurrentStep(number));
  };

  const onNextClick = async () => {
    console.log('isEditMode', isEditMode)
    if (!isEditMode) {
      if (currentStep === 0) {
        const reportCreated = await createReport(report);
        dispatch(updateReportState(reportCreated));
        navigate(`/editReport/${reportCreated.id}`, { replace: true });
      }
      if (currentStep === 4) {
      }
    } else {
      const reportUpdated = await updateReport(report)
      dispatch(updateReportState(reportUpdated))
    }

    if (validateStep(currentStep)) {
      dispatch(setCurrentStep(currentStep + 1));
    }
    return
  }
  const onPrevClick = () => {
    dispatch(setCurrentStep(currentStep - 1));
  }
  const validateStep = () => {
    return true
  }
  const onFormSubmit = async () => {
    if (validateStep()) {
      const reportUpdated = await updateReport(report);

      dispatch(updateReportState(reportUpdated))
      await MapService.sendReportForSubmit(report.id);
      navigate(`/report/${report.id}`);
    }
  }

  return (
    <section className={styles["stepper-section"]}>
      <div>
        <ProgressIndicator className={styles.indicator} currentIndex={currentStep} >
          <ProgressStep label="Step 1" />
          <ProgressStep label="Step 2" />
          <ProgressStep label="Step 3" />
          <ProgressStep label="Step 4" />
        </ProgressIndicator>
      </div>
      <div className={styles["stepper-content"]}>
        {currentStep === 0 && <FirstStep report={report} />}
        {currentStep === 1 && <SecondStep />}
        {currentStep === 2 && <ThirdStep />}
        {currentStep === 3 && <FourthStep />}
      </div>
      <div className={styles["stepper-actions"]}>
        {!!currentStep && <Button kind="secondary" onClick={onPrevClick} >Previous</Button>}
        {currentStep < 3 && <Button onClick={onNextClick} >Next</Button>}
        {currentStep === 3 && <Button onClick={onFormSubmit} >Submit</Button>}
      </div>
    </section>
  );
}
