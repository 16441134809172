import styles from "./ProgressIndicator.module.css";

export default function ProgressIndicator({value}) {
  console.log(value);
  return (
    <div className={styles.indicator}>
      <div style={{width: value + "%"}} className={styles.green}></div>
      <div style={{width: 100 - value + "%"}} className={styles.red}></div>
    </div>
  )
}