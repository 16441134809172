import styles from "./Logo.module.css";
import camel from "./camel.png";

export default function Logo({style = {}}) {
  return (
    <>
      <img style={{ height: "64px", width: "64px", ...style}} src={camel} />
      <div style={{...style}} className={styles["heading-div"]}>
        <h2>Well Integrity Platform</h2>
        <span>Powered by GGOFS</span>
      </div>
    </>
  );
}
