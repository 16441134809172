import "./chartStyles.css";
import "@carbon/charts/styles.css";
import styles from "./MapPanel.module.css";
import { Section, Heading, FormLabel } from "@carbon/react";
import { GaugeChart } from "@carbon/charts-react";
import { CloseOutline } from "@carbon/icons-react";
import ReportsTable from "../ReportsTable/ReportsTable";
import { useNavigate } from "react-router";
import { useEffect, useRef } from "react";
import { MAP_MODE } from "../../data/constants";
import { getMapPanelInfo, getMapItems } from "../../helpers";
import { selectMap, updateMapState } from "../../redux/reducers/MapSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";

export default function MapPanel({ wells, title, description, percentage, onClose, fieldView }) {
  const isMeterOnComplianceMode = useAppSelector(selectMap).isMeterOnComplianceMode

  const whatCalculate = isMeterOnComplianceMode ? 'wellComplained' : 'yearPlanServiced'
  const whatMode = isMeterOnComplianceMode ? 'Wellhead Integrity Compliance' : 'Wells Service PM Yearly Plan'

  const navigate = useNavigate();
  const chartRef = useRef();
  const mode = window.location.hash.slice(1).split("=")[0];
  const id = window.location.hash.slice(1).split("=")[1];
  const fields = useAppSelector(selectMap).fields || [];
  const me = useAppSelector(selectMap).me || {};
  const options = {
    height: "250px",
    width: "250px",
    color: {
      scale: {
        value: "green",
      },
    },

  };
  const totalListOfEntities = getMapItems(mode, fields, id)

  const getCounts = (totalListOfEntities, whatCalculate) => {
    const totalWells = totalListOfEntities.reduce((sum, obj) => sum + obj.totalWells, 0)
    const totalWellDone = totalListOfEntities.reduce((sum, obj) => sum + obj[whatCalculate], 0)
    const totalWellOverdue = totalWells - totalWellDone
    return [totalWells, totalWellDone, totalWellOverdue]
  }

  const percentCalc = (mode) => {
    if (mode === "clients" || mode === '') {

      const [totalWells, totalWellDone, totalWellOverdue] = getCounts(fields, whatCalculate)
      const result = (fields.reduce((sum, obj) => sum + obj[whatCalculate], 0) / totalWells) * 100

      return [result, totalWells, totalWellDone, totalWellOverdue];
    }
    if (mode === 'fields') {
      const [totalWells, totalWellDone, totalWellOverdue] = getCounts(totalListOfEntities, whatCalculate)

      const result = (totalListOfEntities.reduce((sum, obj) => sum + obj[whatCalculate], 0) / totalWells) * 100

      return [result, totalWells, totalWellDone, totalWellOverdue]
    }

    if (mode === 'wells') {
      const field = fields.find((el) => el.id === id);

      const [totalWells, totalWellDone, totalWellOverdue] = getCounts([field], whatCalculate)

      const result = (field[whatCalculate] / field.totalWells) * 100 || 0
      return [result, totalWells, totalWellDone, totalWellOverdue]
    }
    return [percentage || 0, 0, 0, 0]

  }
  const [calculatedPercent, totalWellsCount, totalWellDone, totalWellOverdue] = percentCalc(mode)
  const data = [
    {
      group: "value",
      value: calculatedPercent,
    },
    {
      group: "delta",
      value: "",
    },
  ];
  const headers = [
    {
      key: "wellNumber",
      header: "Well",
    },
    {
      key: "status",
      header: "Status",
    },
  ];
  const onRowClick = (id) => {
    navigate(`/report/${id}`);
  };
  const mapPanelInfo = getMapPanelInfo(mode, fields, me, id) || { title: "", description: "" };

  useEffect(() => {
    document.querySelector(".cds--cc--chart-wrapper>div").style.display = "none";
  }, []);

  return (
    <section className={styles.panel}>
      <div>
        <div className={styles["header-div"]}>
          <Section level={4}>
            <Heading>{mapPanelInfo.title}</Heading>
            <Section>
              <FormLabel>{mapPanelInfo.desciption}</FormLabel>
            </Section>
          </Section>
          {/* {!MAP_MODE && <CloseOutline onClick={onClose} />} */}
        </div>

        <GaugeChart ref={chartRef} data={data} options={options}></GaugeChart>
        <div style={{
          position: "relative",
          bottom: "10vh"
        }}>
          <div>{totalWellDone} out {totalWellsCount} <span style={{ color: "green" }}>done</span></div>
          <div>{totalWellOverdue} <span style={{ color: "red" }}>overdue</span></div>
        </div>
        <div className={styles.title}>  <span>{whatMode}</span></div>
        {mapPanelInfo.showTable && (
          <ReportsTable
            usePagination={false}
            showToolbar={false}
            rows={mapPanelInfo.tableItems}
            headerFields={headers}
            onRowClick={onRowClick}
            noMargin={true}
          />
        )}
      </div>
    </section >
  );
}
