import styles from "./AttachmentsElement.module.css";
import { FileUploaderItem, FileUploaderDropContainer } from "@carbon/react";
import { Download, View } from "@carbon/icons-react";
import MapService from "../../API/service";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateReportState, selectReport } from "../../redux/reducers/ReportSlice";
import { setBusy } from "../../redux/reducers/BusySlice";

export default function AttachmentsElement({ label, attachments, editMode, updateFiles }) {
  const report = useAppSelector(selectReport);
  const id = report.id;
  const files = report.attachments || [];
  const dispatch = useAppDispatch();
  const [stat, setStat] = useState({})
  const sorted = [...files].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
  const items = sorted.map((el) => (
    <div className={styles["uploader-item"]}>
      <FileUploaderItem
        key={el.id}
        name={el.originName}
        status={editMode ? "edit" : "complete"}
        onDelete={() => {
          dispatch(setBusy(true));
          removeFile(id, el.id);
        }}
        style={{ width: "80%", maxWidth: "80%" }}
      />
      <Download
        onClick={() => {
          downloadFile({
            reportId: el.reportId,
            fileName: el.originName,
            dirName: el.fileName,
            mimetype: el.mimetype,
          });
        }}
      />
      {el.originName.includes(".pdf") && (
        <View
          onClick={() => {
            viewFile(el.id, el.mimetype, el.fileName, el.downloadUrl);
          }}
        />
      )}
    </div>
  ));
  const removeFile = async (reportId, fileId) => {
    const resp = await MapService.removeFile(reportId, fileId);

    if (resp.id) {
      dispatch(updateReportState({ ...report, attachments: resp.attachments }));
    }
    dispatch(setBusy(false));
  };
  const downloadFile = async (props) => {
    console.log(props)
    const response = await MapService.getUploadCredentials(props.reportId)
    console.log('----', response)
    await MapService.downloadFile({ ...response, ...props });
  };

  const viewFile = async (id, type, name, downloadUrl) => {
    await MapService.viewFile(id, type, name, downloadUrl);
  };


  useEffect(() => {
    if (document.querySelector("#uploader")) {
      document.querySelector("#uploader").onchange = async (e) => {
        dispatch(setBusy(true));
        const file = e.target.files[0];
        const downloadUrl = await MapService.uploadFileToS3(file, {
          region: stat.region,
          bucketName: stat.Bucket,
          dirName: stat.path,
          accessKeyId: stat.AccessKeyId,
          secretAccessKey: stat.SecretAccessKey,
          sessionToken: stat.SessionToken
        })

        const metadata = {
          reportId: id,
          downloadUrl,
          fileName: stat.path,
          originName: file.name,
          mimetype: file.type,
          size: file.size
        }

        const resp = await MapService.saveMetadata(metadata)

        if (resp.id) {
          dispatch(updateReportState({ ...report, attachments: resp.attachments }));
        }

        dispatch(setBusy(false));
      };
    }
  });

  //   lastModified: 1701745717147
  // lastModifiedDate: Tue Dec 05 2023 06:08:37 GMT+0300 (Moscow Standard Time) {}
  // name: "84c9e141f16d674e02eddf729ceddd9d.jpeg"
  // size: 26166
  // type: "image/jpeg"
  // webkitRelativePath: ""
  // console.log('+++++++++++', stat)

  return (
    <>
      {editMode ? (
        <div className={styles["uploader-div"]}>
          <strong className="cds--file--label">Report files</strong>
          <p className="cds--label-description">500mb max file size(.jpg, .png, .pdf)</p>
          <FileUploaderDropContainer
            id="uploader"
            className={styles["uploader-container"]}
            innerRef={{
              current: "[Circular]",
            }}
            disabled={false}
            labelText="Drag and drop files here or click to upload"
            multiple
            name=""
            size="md"
            onClick={async () => {
              const response = await MapService.getUploadCredentials(id)
              setStat(response)
            }}
          />
          {items}
        </div>
      ) : (
        <>
          <label className={styles.label}>{label}</label>
          {items}
        </>
      )}
    </>
  );
}
