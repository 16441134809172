import styles from "./MapComponent.module.css";
import { MapContainer, TileLayer } from "react-leaflet";
import MapComponent from "./MapComponent";
import Point from "../Point/Point";
import Field from "../Field/Field";
import { useState, useRef, useEffect } from "react";
import Dashboard from "../Dashboard/Dashboard";
import MapPanel from "../MapPanel/MapPanel";
import MapSearch from "../MapSearch/MapSearch";
import { useNavigate } from "react-router-dom";
import { selectMap, updateMapState } from "../../redux/reducers/MapSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getMapItems } from "../../helpers";
import { MAP_MODE } from "../../data/constants";

export default function Map({ center, zoom, bounds }) {
  const isMeterOnComplianceMode = useAppSelector(selectMap).isMeterOnComplianceMode;
  const whatPalletToUse = isMeterOnComplianceMode ? 'colorCompliance' : 'colorYearPlanService'
  const whatPercentToUse = isMeterOnComplianceMode ? 'wellComplained' : 'yearPlanServiced'

  const [iconSize, setIconSize] = useState([5, 5]);
  const [fieldSize, setFieldSize] = useState([100, 100]);
  const [fieldView, setFieldView] = useState(true);
  const fields = useAppSelector(selectMap).fields || [];
  const wells = useAppSelector(selectMap).wells || [];
  const me = useAppSelector(selectMap).me || {};
  const initialPercentage =
    (fields.reduce((sum, obj) => sum + obj[whatPercentToUse], 0) / fields.reduce((sum, obj) => sum + obj.totalWells, 0)) *
    100;
  const [percentage, setPercentage] = useState(0);
  const [fieldName, setFieldName] = useState("");
  const [clientName, setClientName] = useState("");
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const mode = window.location.hash.slice(1).split("=")[0];
  const id = window.location.hash.slice(1).split("=")[1];
  const onFieldClick = (id) => {
    navigate(`#wells=${id}`);
  };
  const onWellClick = (id) => {
    navigate(`/well-state/${id}`);
  };
  // const onWellClick = (id) => {
  //   navigate(`/reports/${id}`);
  // };
  const onClientClick = (id) => {
    navigate(`#fields=${id}`);
  };
  const items = getMapItems(mode, fields, id);
  const clients = items.map((client) => {
    return <Field
      key={client.id}
      position={client.coordinates}
      click={() => {
        onClientClick(client.id);
      }}
      size={fieldSize[0]}
      percentage={(client[whatPercentToUse] / client.totalWells) * 100 || 0}
      value={client.totalWells}
      name={client.name}
    />
  });

  const fieldsArr = items.map((field, ind) => (
    <Field
      key={field.id}
      position={field.coordinates}
      click={() => {
        onFieldClickDecorator(field.id);
      }}
      size={fieldSize[0]}
      percentage={(field[whatPercentToUse] / field.totalWells) * 100 || 0}
      value={field.totalWells}
      name={field.name}
    />
  ));
  const pointsArr = items.map((point) => (
    <Point
      className="oil-point"
      key={point.id}
      wellNumber={point.wellNumber}
      color={point[whatPalletToUse]}
      position={[point.latitude, point.longitude]}
      size={iconSize}
      click={() => {
        onWellClick(point.id);
      }}
    />
  ));
  const closePanel = () => {
    window.history.go(-1);
  };

  const onFieldClickDecorator = (id) => {
    const field = fields.find((el) => el.id === id);
    onFieldClick(id);
    setPercentage((field[whatPercentToUse] / field.totalWells) * 100 || 0);
    setFieldView(false);
    setClientName(field.client.fullName);
    setFieldName(field.name);
  };


  return (
    <div style={{ width: "100%" }}>
      <MapSearch />
      <MapContainer className={styles.map} ref={mapRef} center={center} zoom={zoom} minZoom={zoom} maxBounds={bounds} scrollWheelZoom={true}>
        <MapComponent defaultZoom={zoom} setIconSize={setIconSize} setFieldSize={setFieldSize} />
        <TileLayer
          attribution=''
          // url="https://5c95-37-214-38-224.ngrok-free.app/tile/{z}/{x}/{y}.png"
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          url="http://map.ggofs-stage.org/tile/{z}/{x}/{y}.png"
        />
        {/* {!fieldView && pointsArr}
        {fieldView && fieldsArr} */}
        {mode === MAP_MODE.clients && clients}
        {mode === MAP_MODE.fields && fieldsArr}
        {mode === MAP_MODE.wells && pointsArr}
      </MapContainer>
      <Dashboard />
      <MapPanel
        title={clientName}
        percentage={fieldView ? initialPercentage : percentage}
        wells={wells}
        description={fieldView ? "" : fieldName}
        onClose={closePanel}
        fieldView={fieldView}
      />
    </div>
  );
}
