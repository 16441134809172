import styles from "./FieldContent.module.css";
import { useState, useEffect } from "react";
import { Button, ComboBox, TextInput } from "@carbon/react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../redux/hooks";
import { setBusyState } from "../../helpers/index.js"

import MapService from "../../API/service";

export default function FieldContent({ onActionClick, submitChanges, fieldId }) {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [isCoordsValid, setIsCoordsValid] = useState(true);

  const [allValuesValid, setAllValuesValid] = useState(false);


  const [clients, setClients] = useState([])
  const [field, setField] = useState({
    client: '',
    name: '',
    location: '',
    latitude: '',
    longitude: ''
  })
  useEffect(() => {
    const getFullList = async () => {
      try {
        const res = await MapService.getFullList();

        setClients(res);
      } catch (error) {
        setBusyState(dispatch, false);
        return
      }
    };
    const getField = async (id) => {
      try {
        const currentField = await MapService.getFieldById(id);
        setField({ ...currentField, client: currentField.client.fullName, location: `${currentField.latitude},${currentField.longitude}` })
      } catch (error) {
        setBusyState(dispatch, false);
        return;
      }

    };
    setBusyState(dispatch, true);
    getFullList();
    if (fieldId) {
      getField(fieldId)
    }
    setBusyState(dispatch, false);
  }, []);

  const onFieldChange = (fieldName, value = "", regExp, setFunc) => {
    if (regExp && regExp.test(value)) {
      setFunc(regExp.test(value));
    } else if (regExp && !regExp.test(value)) {
      setFunc(regExp.test(value));

      return;
    }

    setField({ ...field, [fieldName]: value })
  };

  const validateFields = () => {
    const location = field.location
    console.log(location)
    const regExp = /^[-+]?\d{1,2}\.\d+,[-+]?\d{1,2}\.\d+$/
    if (regExp.test(location)) {
      setIsCoordsValid(true)
    } else {
      setIsCoordsValid(false)
      return false
    }

    if (!field.name || !field.name.length || field.name === 'null') {
      return false
    }

    if (!field.client || !field.client.length || field.client === 'null') {
      return false
    }
    return true
  }
  console.log('field', field)
  return (
    <div className={styles["report-form"]} >

      <section>
        <TextInput
          value={field.name}
          id="name"
          type="text"
          labelText="name"
          onChange={(e) => {
            const value = e.target.value

            setField({ ...field, name: value })

          }}
        />
        <ComboBox
          onChange={(e) => {
            setField({ ...field, client: e.selectedItem })
            // onFieldChange("client", e.selectedItem);
            // onClientChange(e.selectedItem);
          }}
          id="client"
          items={clients.map((el) => el.fullName)}
          itemToString={(item) => item || ""}
          titleText="Client"
          selectedItem={field?.client || ''}
        />
        <TextInput
          value={field.location}
          id="location"
          type="text"
          labelText="Location"
          onChange={(e) => {
            const value = e.target.value
            let [latitude, longitude] = e.target.value.split(',')
            if (latitude) {
              latitude = latitude.replace(/ /gi, '')
            }
            if (longitude) {
              longitude = longitude.replace(/ /gi, '')
            }


            setField({ ...field, location: value, longitude, latitude })

          }}
          invalid={!isCoordsValid}
          invalidText="Invalid coordinate format, please use the following one **.***,**.***"
        />

        <div className={styles.actions}>
          <Button
            onClick={async () => {
              if (allValuesValid) {
                setBusyState(dispatch, true);
                const updatedField = await MapService.updateFieldChanges(field)
                console.log('+++++', updatedField)
                setField({ ...updatedField, client: updatedField.client.fullName, location: `${updatedField.latitude},${updatedField.longitude}` })
                setAllValuesValid(false)
                setBusyState(dispatch, false);
                navigate(`/field/${updatedField.id}`)
              } else {
                const isValid = validateFields()
                console.log('isValid', isValid)
                setAllValuesValid(isValid)
              }

            }}
            kind="primary"
          >
            {allValuesValid ? "Save" : "Validate"}
          </Button>
        </div>
      </section>
    </div>

  );
}
