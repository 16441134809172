import L from "leaflet";
import { Marker } from "react-leaflet";
import Donut from "../Donut/Donut";
import ReactDOMServer from "react-dom/server";

export default function Field({ position, percentage = 0, size, value, click, name }) {
  const donut = <Donut percentage={percentage} size={size} value={value} name={name} />
  const svgString = ReactDOMServer.renderToString(donut);
  const svgIcon = L.divIcon({
    className: "svg-icon",
    html: svgString,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2]
  });
  const onFieldClick = (e) => {
    click();
  }

  return <Marker eventHandlers={{ click: onFieldClick }} icon={svgIcon} position={position} />;
}