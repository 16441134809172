import { useEffect, useState } from "react";
import styles from "./FourthStep.module.css";
import { FileUploaderDropContainer, FileUploaderItem } from "@carbon/react";
import MapService from "../../API/service";
import AttachmentsElement from "../AttachmentsElement/AttachmentsElement";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateReportState, selectReport } from "../../redux/reducers/ReportSlice";

export default function FourthStep() {
  const report = useAppSelector(selectReport);

  return (
    <>
      <div className={styles["uploader-div"]}>
        <AttachmentsElement editMode={true} attachments={report.attachments} />
      </div>
    </>
  );
}
