import styles from "./Donut.module.css";
/**
 * https://stackoverflow.com/questions/44006664/the-calculation-behind-progress-circle-using-dasharray-and-dashoffset#:~:text=PI%20*%2022.5)%20%3D-,141.37,-Secondly%2C%20you%20can
 * https://www.geeksforgeeks.org/css-stroke-dashoffset-property/
 */
export default function Donut({ percentage, size, value, name }) {
  const radius = size * 0.8 / 2;
  const realRad = size * 0.8 / 2;
  const circumference = 2 * Math.PI * realRad;
  const greenCircleDashArray = circumference;
  // const greenCircleDashOffset = (circumference * (100 - percentage)) / 100;
  const greenCircleDashOffset = percentage * greenCircleDashArray / 100
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <span style={{ position: "absolute", textAlign: "center", "top": "50%", "width": "100%", "zIndex": 100000, transform: "translateY(-50%)", textAlign: "center", fontSize: size / 10 }}>{name}</span>
      <svg width={size} height={size}>
        <g transform={`rotate(-90 ${size / 2} ${size / 2})`}>
          <circle
            className={styles.donut}
            r={radius}
            cx="50%"
            cy="50%"
            stroke="white"
            strokeWidth="10"
            strokeDasharray={greenCircleDashArray}
            strokeDashoffset="0"
          ></circle>
          <circle
            r={radius - 5}
            cx="50%"
            cy="50%"
            fill="transparent"
            stroke="red"
            strokeWidth="13"
            strokeDasharray={0}
            strokeDashoffset="0"
          ></circle>
          <circle
            r={radius - 5}
            cx="50%"
            cy="50%"
            fill="transparent"
            stroke="green"
            strokeWidth="13"
            strokeDasharray={`${greenCircleDashOffset}, ${greenCircleDashArray}`}
          // strokeDashoffset={greenCircleDashOffset}
          ></circle>
        </g>
        {/* <text x="50%" y="50%" dominantBaseline="central" style={{width: "20px"}} textAnchor="middle" fontSize={size/8}>
        {name}%
      </text> */}
      </svg>
    </div>
  );
}
