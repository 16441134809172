import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showModalWithChanges: false
};

export const appHeaderSlice = createSlice({
    name: "appHeader",
    initialState,
    reducers: {
        updateModal: (state, action) => {
            state.showModalWithChanges = action.payload
        }
    },
});

export const { updateModal } = appHeaderSlice.actions

export const selectAppHeader = (state) => state.appHeader;

export default appHeaderSlice.reducer;