import AppHeader from "../components/Header/AppHeader";
import ReportForm from "../components/ReportForm/ReportForm";
import ReportHeader from "../components/ReportHeader/ReportHeader";
import Stepper from "../components/Stepper/Stepper";
import MapService from "../API/service";
import { useAppDispatch } from "../redux/hooks";
import { setMode } from '../redux/reducers/StepperSlice'
import { updateToDefault } from '../redux/reducers/ReportSlice'

export default function List() {
  const id = 123;
  const dispatch = useAppDispatch()
  dispatch(setMode("create"))
  dispatch(updateToDefault())

  return (
    <section className="report-section">
      <AppHeader />
      <ReportForm
        header={<ReportHeader title="Well integrity report" description={`ID: ${id}`} />}
        content={<Stepper />}
      />
    </section>
  );
}
