import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

export const busySlice = createSlice({
    name: "busy",
    initialState,
    reducers: {
        setBusy: (state, action) => action.payload,
        updateToDefault: (state) => false
    },
});

export const { setBusy, updateToDefault } = busySlice.actions

export const getBusy = (state) => state.busy;

export default busySlice.reducer;