import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    unapproved: []
}

export const wellWithStatusSlice = createSlice({
    name: "wellWithStatus",
    initialState,
    reducers: {
        updateWellsStatusState: (state, action) => {
            const payload = action.payload
            Object.keys(payload).map(key => state[key] = payload[key])
        },
    },
});

export const { updateWellsStatusState } = wellWithStatusSlice.actions

export const selectWellsWithState = (state) => state.wellWithStatus;

export default wellWithStatusSlice.reducer;