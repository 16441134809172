import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clients: [],
    wells: [],
    fields: [],
    client: [],
    field: null,
    fieldLocation: null,
    well: null,
    location: null,
    disableWellChoose: false,
    disableFieldChoose: false,
    disableClientChoose: false,
    changes: []
}

export const reportContentSlice = createSlice({
    name: "reportContent",
    initialState,
    reducers: {
        updateReportContentState: (state, action) => {
            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value
            }
        }
    },
});

export const { updateReportContentState } = reportContentSlice.actions

export const selectReportContent = (state) => state.reportContent;

export default reportContentSlice.reducer;