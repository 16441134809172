import styles from "./FormElement.module.css";
import icon from "./icon.png";
import moment from "moment";
import { STATUSES } from "../../data/constants";
import {
  FormLabel,
  TextInput,
  ContentSwitcher,
  Switch,
  Dropdown,
  MultiSelect,
  DatePicker,
  DatePickerInput,
  TextArea
} from "@carbon/react";

export default function FormElement({
  label,
  fieldValue,
  underscored,
  editMode,
  fieldToUpdate = "",
  onFieldChange,
  fieldType,
  fieldValues,
  disabled = false,
  options
}) {
  const textClassName = `${styles["form-value"]} ${label === "Status" ? styles[STATUSES[fieldValue]] : ""}`;
  let control;

  if (fieldType === 'operations') {
    control = (
      <div>
        {/* <div className={styles["radio-div"]}>
          <ContentSwitcher
            className={styles.switcher}
            selectedIndex={fieldToUpdate !== "Rigless" ? 1 : 0}
            onChange={(e) => {
              onFieldChange("operations", e.name);
            }}
          >
            <Switch name={"Rigless"} text="Rigless" />
            <Switch name={"Rig Number"} text="RigNumber" />
          </ContentSwitcher>
        </div> */}
        <TextInput
          value={fieldValue}
          id="operation_string"
          type="text"
          onChange={(e) => {
            onFieldChange(fieldToUpdate, e.target.value);
          }}
          invalidText="Invalid Rig Number format"
        />
      </div>)
  } else if (fieldType === "switch") {
    if (options) {
      control = (<ContentSwitcher
        className={styles.switcher}
        selectedIndex={
          options.findIndex((v) => v && v === fieldValue) || 0
        }
        onChange={(e) => {
          onFieldChange("wellHeadRemedialClampRequired", e.name);
        }}
      >
        {options.map(option => <Switch name={`${option}`} text={`${option}`} />)}
      </ContentSwitcher>)
    } else {
      control = (
        <ContentSwitcher
          selectedIndex={fieldValue === "No" ? 1 : 0}
          className={styles.switcher}
          onChange={(e) => {
            onFieldChange(fieldToUpdate, e.name);
          }}
        >
          <Switch name={"Yes"} text="Yes" />
          <Switch name={"No"} text="No" />
        </ContentSwitcher>
      );
    }

  } else if (fieldType === "combobox") {
    control = (
      <Dropdown
        id={fieldToUpdate}
        onChange={(e) => {
          onFieldChange(fieldToUpdate, e.selectedItem);
        }}
        items={fieldValues}
        itemToString={(item) => item || ""}
        selectedItem={fieldValue || null}
        disabled={disabled}
      />
    );
  } else if (fieldType === "multiSelect") {
    control = (
      <MultiSelect
        id={fieldToUpdate}
        items={fieldValues}
        itemToString={(item) => item || ""}
        onChange={(e) => {
          onFieldChange(fieldToUpdate, e.selectedItems);
        }}
        initialSelectedItems={fieldValue}
        disabled={disabled}
        key={fieldValue?.length}
      />
    );
  } else if (fieldType === "date") {
    control = (
      <DatePicker
        onChange={(e) => { onFieldChange("nextVisit", e[0]?.getTime() || 0) }}
        className={styles["date-picker"]}
        width="100%"
        dateFormat="d.M.Y"
        datePickerType="single"
        value={+fieldValue}
      >
        <DatePickerInput style={{ width: "100%" }} id="date-picker-calendar-id" placeholder="mm/dd/yyyy" type="text" />
      </DatePicker>
    );
  } else if (fieldType === "Recommendation") {
    control = (
      <TextArea
        value={fieldValue}
        disabled={!fieldToUpdate || disabled}
        onChange={(e) => {
          onFieldChange(fieldToUpdate, e.target.value);
        }}
      />
    );
  }
  else {
    control = (
      <TextInput
        value={fieldValue}
        disabled={!fieldToUpdate || disabled}
        onChange={(e) => {
          onFieldChange(fieldToUpdate, e.target.value);
        }}
      />
    );
  }

  return (
    <div className={`${styles["flex-content"]} ${underscored ? styles.underscored : ""}`}>
      <FormLabel style={{ flexBasis: "50%" }}> {label} </FormLabel>
      {editMode ? (
        control
      ) : (
        <div className={styles.value}>
          {fieldValue === STATUSES.approved && <img src={icon} />}
          <span style={{ display: !editMode ? "block" : "none" }} className={textClassName}>
            {fieldType === "date" ? moment(+fieldValue).format("DD.MMM.YYYY") : fieldType === "multiSelect" ? fieldValue?.join(", ") : fieldValue}
          </span>
        </div>
      )}
    </div>
  );
}
