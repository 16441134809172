import "./Point.css"
import { Marker } from "react-leaflet";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import Well from "../Donut/Well";

export default function Point({ wellNumber, color, position, size, click }) {
  const donut = <Well size={size}name={wellNumber} color={color} />
  const svgString = ReactDOMServer.renderToString(donut);
  const svgIcon = L.divIcon({
    className: "svg-icon",
    html: svgString,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
  });

  return <Marker title={wellNumber} icon={svgIcon} eventHandlers={{ click: click }} position={{lat: position[0], lng: position[1]}} />;
}


// export default function Point({ wellNumber, color, position, size, click }) {
//   console.log(color)
//   const icon = new Icon({
//     iconUrl: `/${color}.svg`,
//     iconSize: size,
//     popupAnchor: null,
//     shadowUrl: null,
//     shadowSize: null,
//     shadowAnchor: null,
//   });

//   return <Marker title={wellNumber} eventHandlers={{ click: click }} icon={icon} position={position} />;
// }