import {
  FormLabel,
  ContentSwitcher,
  Switch,
  TextInput,
  ComboBox,
  Dropdown,
  Button,
  Modal,
  FlexGrid,
  Column,
  Row,
  ModalFooter,
  ModalBody,
} from "@carbon/react";
import { useEffect, useState } from "react";

import { useAppDispatch } from "../../redux/hooks.js";
import { setBusyState } from "../../helpers/index.js";
import {
  selectReport,
  updateReportState,
} from "../../redux/reducers/ReportSlice";

import MapService from "../../API/service.js";

export default ({
  report,
  oldWell,
  setShowLocationModal,
  showModal,
  resetAllValuesAfterEditLocation,
}) => {
  const dispatch = useAppDispatch();

  const [well, setWell] = useState({
    field: "",
    wellNumber: "",
    location: "",
    latitude: "",
    longitude: "",
  });

  const flushState = () =>
    setWell({
      field: "",
      wellNumber: "",
      location: "",
      latitude: "",
      longitude: "",
    });

  const [isCoordsValid, setIsCoordsValid] = useState(false);
  const [isNameValid, setIsNameValid] = useState(false);
  const [isFieldValid, setIsFieldValid] = useState(false);
  const [allValuesValid, setAllValuesValid] = useState(false);

  useEffect(() => {
    if (!report.field || !report.wellNumber) {
      alert("NEED FIELD/WELL NUMBER");
    } else {
      setWell({
        ...well,
        id: oldWell.id,
        field: report.field,
        wellNumber: report.wellNumber,
        location: report.location,
      });

      const regExp = /^[-+]?\d{1,2}\.\d+,[-+]?\d{1,2}\.\d+$/;
      const regExpSpace = /^[-+]?\d{1,2}\.\d+, *[-+]?\d{1,3}\.\d+$/;
      if (regExp.test(report.location) || regExpSpace.test(report.location)) {
        setIsCoordsValid(true);
      } else {
        setIsCoordsValid(false);
      }
    }
  }, []);

  const validateFields = () => {
    const location = well.location;
    console.log(location);
    /**
     * Объяснение:
      ^: Начало строки.
      [-+]?: Опциональный знак - или +.
      \d{1,2}: Одна или две цифры (градусы широты).
      \.: Точка.
      \d+: Одна или более цифр (дробная часть широты).
      ,: Запятая.
      *: Ноль или более пробелов.
      [-+]?: Опциональный знак - или +.
      \d{1,3}: Одна, две или три цифры (градусы долготы, чтобы учитывать до 180).
      \.: Точка.
      \d+: Одна или более цифр (дробная часть долготы).
      $: Конец строки.
      Теперь регулярное выражение позволяет наличие пробелов после запятой. Примеры, которые соответствуют:

      40.741895,-73.989308
      40.741895, -73.989308
      40.741895, -73.989308
      40.741895, 73.989308
      -40.741895, 73.989308
      40.741895,73.989308
      -40.741895,-73.989308
      Это регулярное выражение корректно обрабатывает наличие пробелов после запятой.
     */
    const regExp = /^[-+]?\d{1,2}\.\d+,[-+]?\d{1,2}\.\d+$/;
    const regExpSpace = /^[-+]?\d{1,2}\.\d+, *[-+]?\d{1,3}\.\d+$/;
    if (regExp.test(location) || regExpSpace.test(location)) {
      setIsCoordsValid(true);
    } else {
      setIsCoordsValid(false);
      return false;
    }

    if (
      !well.wellNumber ||
      !well.wellNumber.length ||
      well.wellNumber === "null"
    ) {
      setIsNameValid(false);
      return false;
    } else {
      setIsNameValid(true);
    }

    if (!well.field || !well.field.length || well.field === "null") {
      setIsFieldValid(false);
      return false;
    } else {
      setIsFieldValid(true);
    }

    setAllValuesValid(true);
    return true;
  };
  console.log(well);
  return (
    <Modal
      modalHeading="Edit location"
      open={showModal}
      primaryButtonText={allValuesValid ? "Create" : "Can't"}
      secondaryButtonText="Validate"
      onRequestClose={() => {
        setShowLocationModal(false);
      }}
      onRequestSubmit={async () => {
        if (allValuesValid) {
          try {
            setBusyState(dispatch, true);
            const updatedWell = await MapService.updateWellChanges(well);
            setBusyState(dispatch, false);

            flushState();
            dispatch(
              updateReportState({
                longitude: updatedWell.longitude,
                latitude: updatedWell.latitude,
                location: `${updatedWell.latitude}, ${updatedWell.longitude}`,
              })
            );
            // resetAllValuesAfterEditLocation(updatedWell);
            setShowLocationModal(false);
            console.log("WHAT");
          } catch (error) {
            console.log(error.message);
            alert(error.message);
            setAllValuesValid(false);
            setBusyState(dispatch, false);
          }
        } else {
          const isValid = validateFields();
          console.log("isValid", isValid);
          setAllValuesValid(isValid);
        }
      }}
      onSecondarySubmit={() => {
        const isValid = validateFields();
        setAllValuesValid(isValid);
      }}
    >
      <ModalBody hasScrollingContent>
        <p
          style={{
            marginBottom: "1rem",
          }}
        >
          Change for Well {report.wellNumber} Location to {report.location}
        </p>
        <TextInput
          data-modal-primary-focus
          id="location"
          type="text"
          labelText="Location (latitude,longitude)"
          placeholder="Type location"
          style={{
            marginBottom: "1rem",
          }}
          value={well.location}
          onChange={(e) => {
            console.log("------");
            const value = e.target.value;
            let [latitude, longitude] = e.target.value.split(",");
            if (latitude) {
              latitude = latitude.replace(/ /gi, "");
            }
            if (longitude) {
              longitude = longitude.replace(/ /gi, "");
            }
            setWell({ ...well, location: value, longitude, latitude });
            setAllValuesValid(false);
          }}
          invalid={!isCoordsValid}
          invalidText="Invalid coordinate format, please use the following one **.***,**.***"
        />
      </ModalBody>

      {/* <ModalFooter>
        <Button kind="secondary" onClick={() => {}}>
          Secondary button
        </Button>
      </ModalFooter> */}
    </Modal>
  );
};
