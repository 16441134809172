import AppHeader from "../components/Header/AppHeader";
import ReportForm from "../components/ReportForm/ReportForm";
import ReportHeader from "../components/ReportHeader/ReportHeader";
import Stepper from "../components/Stepper/Stepper";
import MapService from "../API/service";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { updateReportState } from "../redux/reducers/ReportSlice";
import { setMode } from "../redux/reducers/StepperSlice";

export default function Edit() {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    async function fetchReport() {
      const report = await MapService.getReport(id);

      dispatch(updateReportState(report));
      dispatch(setMode("edit"));
    }

    fetchReport();
  }, [id]);

  return (
    <section className="report-section">
      <AppHeader />
      {id ? (
        <ReportForm
          header={<ReportHeader title="Well integrity report" description={`ID: ${id}`} />}
          content={<Stepper />}
        />
      ) : (
        <></>
      )}
    </section>
  );
}
