export const validateLocation = (sourceLocation) => {
    const location = sourceLocation;
    const regExp = /^[-+]?\d{1,2}\.\d+,[-+]?\d{1,2}\.\d+$/;
    const regExpSpace = /^[-+]?\d{1,2}\.\d+, *[-+]?\d{1,3}\.\d+$/;
    if (regExp.test(location) || regExpSpace.test(location)) {
        return true
    }
    return false
};

export const parseLocation = (sourceLocation) => {
    console.log("parseLocation", sourceLocation);
    let [latitude, longitude] = sourceLocation.split(",");
    if (latitude) {
        latitude = latitude.replace(/ /gi, "");
    }
    if (longitude) {
        longitude = longitude.replace(/ /gi, "");
    }

    return [latitude, longitude]
}

export const getCurrentWellState = (report, changes) => {
    return report.well.id == changes.id ? changes : report.well
}

export const getCurrentFieldState = (report, changes) => {
    return report.well.field.id == changes.id ? changes : report.well.field
}

export const getCurrentState = (report, changes, editVisible) => {
    const freshChanges = [...changes]
    const actualChanges = (editVisible && freshChanges.length) ? freshChanges[freshChanges.length - 1] : null
    const well = actualChanges && actualChanges.toState.well ? getCurrentWellState(report, actualChanges.toState.well) : report.well
    const field = actualChanges && actualChanges.toState.field ? getCurrentFieldState(report, actualChanges.toState.field) : report?.well?.field
    const actualLocation = `${well?.latitude}, ${well?.longitude}`
    const actualWellNumber = well.wellNumber
    const actualFieldName = field.name
    const actualFieldLocation = `${field.latitude, field.longitude}`



    return { actualLocation, actualWellNumber, actualFieldName, actualFieldLocation }
}

const isComplianceTrue = (report) => {

    return report.wellHeadRemedialClampRequired !== "Required" && !report.repairService.length
}


export const getWellStatus = (report) => {
    const currentDate = new Date().getTime()
    const reportLastApproved = report
    const daysUntilNextVisit = (reportLastApproved.nextVisit - currentDate) / (24 * 60 * 60 * 1000);
    let status = false

    if (daysUntilNextVisit <= 0) {
        status = false

    } else if (daysUntilNextVisit <= 30 && daysUntilNextVisit > 0) {
        status = false
    } else {
        if (isComplianceTrue(reportLastApproved)) {
            status = true
        } else {
            status = false
        }

        status = true
    }

    return status
}