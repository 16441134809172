export const NEXT_VISIT = ["Annual Preventive Maintenance", "Repair"];
export const OPERATION = ["Rigless", "Rig"];
export const NUM_VISITS = Array.from({ length: 100 }, (_, index) => index + 1);
export const WELL_HEAD_SIZE = [
  ".2-9/16 3k",
  ".3-1/8 3k",
  ".3-1/8 5k",
  ".4-1/16 5k",
  ".2-9/16 5k",
  ".2-9/16 10k",
  ".3-1/16 10k",
  ".2-9/16 10k",
  "3”LP",
  ".3-1/6 3k",
  ".3-1/8 2k",
  ".4-1/16 2k",
  "4-1/16 2k",
  '2-1/16"',
  "2-1/16 3k",
  "2-1/2''",
  "3'' 3k",
  ".2-1/2'' 3K",
  "N/C",
  '3" LP 3k',
  ".2-1/16 3k",
  ".2-1/16 2k",
  ".4-1/8 2k",
  ".4-1/16 3k",
  ".2-9/16 2k",
  ".3-1/16 2k",
  ".2-1/16 5k",
  `3-1/8" X 3''`,
  ".2-1/16 3k",
  ".2 FE 2k 21/2 LP",
  ".2 LP 2k",
  ".2 LP 500 psi",
  ".2-1/16 2k LP",
  ".2-1/2 2k",
  ".2-1/2 2k 2 LP",
  ".2-1/2 3k",
  ".2-1/2 3k LP",
  ".2-1/2 8 2k LP",
  ".2-1/2 2k LP",
  ".2-1/2 x 2-1/2 2k LP",
  ".2-1/2 x 2-9/16 2k",
  ".2-1/6 2k",
  ".3 LP",
  ".3 LP 2k",
  ".3 LP 3k",
  ".3-1/8 2k LP",
  ".3-1/8 3 LP 2k",
  ".4 2k 4-1/2 LP",
  ".4 3k 3 LP",
  ".4 LP 3k",
  ".4-1/16 2k 4 LP",
  ".4-1/2 LP 960 psi",
  ".4-1/2 x 4-1/8 3k LP",
  ".4-1/8 3k",
  ".4-1/8 960psi 4-1/2",
  ".3-1/16 3k",
  ".2-1/2 2k 2-1/2 LP",
  ".2-1/2 2k  LP",
  ".2 1/2 2k",
  ".4-1/16 3k",
  ".4-1/16 10k",
  ".3-1/8 2k",
  ".2-9/16 2k",
  ".4-1/18 3k",
  ".3-1/8 5k",
  "2 /2 x 29/16 3k",
  ".7-1/16 3k",
  ".2'' LP",
  ".3'' LP",
  ".2'' LP X 2'' LP 2k",
  ".5-1/8 3k",
];

export const CLIENTS = [
  "Arabian Gulf Oil Company AGOCO",
  "Sirte Oil Company SOC",
  "Waha Oil Company WOC",
  "Melitah Oil and Gas MOG",
  "Mabruk Oil Company MOC",
  "Akakus Oil Operation AOO",
  "Zuitnina Oil Company ZOC",
  "Hourge Oil Operation HOO",
  "Zellaf Oil",
];

export const WELL_TYPE = [
  "Oil",
  "Gas",
  "Water Injection",
  "UNKNOWN",
  "EXPLORATION",
  "Observation",
];

export const PRODUCTION_DRIVE = [
  "Natural",
  "ESP",
  "Gas Lift",
  "Suckerod",
  "UNKNOWN",
  "Test",
  "Workover",
];

export const WELL_INTEGRITY_SERVICE = [
  "Well Head Maintenance",
  "Well Head Remedial",
  "Well Head Special Service",
];

export const WELL_HEAD_REMEDIAL_SERVICE_TYPE = [
  "Well Head Clamp Installation",
  "Well Head Remedial",
  "Site visit",
];

export const WELL_HEAD_MAINTENANCE_SERVICE_TYPE = [
  "Preventive Maintenance",
  "Routine Maintenance",
  "Non Routine Maintenance",
  "Workshop Work",
  "Scouting Well",
  "Site visit",
];

export const WELL_HEAD_SPECIAL_SERVICE_TYPE = [
  "Hot tap",
  "VR Plug",
  "BPV Back Pressure Valve",
  "TWCV Two Way Check valve",
  "GVD Gate Valve Drilling",
  "Site visit",
];

export const WELL_INTEGRITY_SERVICE_TO_SERVICE_TYPE = {
  "Well Head Maintenance": WELL_HEAD_MAINTENANCE_SERVICE_TYPE,
  "Well Head Remedial": WELL_HEAD_REMEDIAL_SERVICE_TYPE,
  "Well Head Special Service": WELL_HEAD_SPECIAL_SERVICE_TYPE,
};

export const CELLAR_AVAILABILITY = ["Yes", "No"];

export const CELLAR_REQUIRED = ["Yes", "No"];

export const WELL_COMPLIANCE = ["Yes", "No"];

export const WORK_REQUIRED = ["Repair Services", "Well Head Remedial", "NA"];

export const EXTRA_WORK_REQUIRED = ["Y", "N"];

export const REPAIR_SERVICES = [
  "Hot Tap",
  "Gate Valve Drilling",
  "Back Pressure Valve",
  "Valve Replacement",
  "Seal Title",
  "VR Plug",
  "BPV Lubricator",
];

export const WELL_HEAD_REMEDIAL = [
  "Clamp",
  "Casing Patch",
  "Flange Spreader",
  "Nut Splitter",
  "Cellar Construction",
  "Inspection Services",
  "Welding Services",
  "NA",
];

export const WELL_HEAD_REMEDIAL_CLAMP_REQUIRED = ["Y", "N"];

export const NON_ROUTINE_WORK = [
  "Valve Replacement",
  "Christmas Tress Service or Replacement",
  "Flow line replacement",
  "NA",
];

export const WORKSHOP_WORK = [
  "Valve Refurbishment",
  "Christmas Tree Refurbishment",
  "Inspection and Repair",
  "NA",
];

export const WORKSHOP_WORK_BOOLEAN = ["Y", "N"];
