import { setBusy } from "../redux/reducers/BusySlice";

const sortClients = (uniqItems) => {
  const sortedClients = Object.keys(uniqItems).reduce((acc, item) => {
    if (!item) {
      acc[0].push(item)
      return acc
    }
    const isWaha = item.match("Waha")
    const isAgoco = item.match("AGOCO")
    const isHourage = item.match("Hourge")
    const isSirte = item.match("Sirte")
    if (isAgoco) {
      acc[1].push(item)
    }
    if (isWaha) {
      acc[0].push(item)
    }

    if (isHourage) {
      acc[2].push(item)
    }
    if (isSirte) {
      acc[3].push(item)
    }
    if (!isWaha || !isAgoco || !isHourage || !isSirte) {
      acc[4].push(item)
    }
    return acc
  }, [[], [], [], [], []]).flat()
  return sortedClients.reduce((acc, key) => {
    acc[key] = uniqItems[key]
    return acc
  }, {})

}

export const calculateRemainingTime = (targetDate = new Date()) => {
  const now = new Date();
  const timeDifference = now - targetDate;

  if (timeDifference <= 0) {
    return "00:00:00"; // Return zero if the target date is in the past
  }

  const totalSeconds = Math.floor(timeDifference / 1000);
  const remainingHours = Math.floor(totalSeconds / 3600);
  const remainingMinutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;

  return `${(23 - remainingHours).toString().padStart(2, "0")}:${(59 - remainingMinutes)
    .toString()
    .padStart(2, "0")}:${(60 - remainingSeconds).toString().padStart(2, "0")}`;
};

export const setBusyState = (dispatch, value) => {
  dispatch(setBusy(value));
};

export const getDashboardItems = (mode, fields, id) => {
  if (mode === "clients" || mode === "") {
    const uniqItems = {};

    fields.forEach((el) => {
      if (!(el.client.fullName in uniqItems)) {
        uniqItems[el.client.fullName] = {
          ...el.client,
          name: el.client.fullName,
          totalWells: el.totalWells,
          wellComplained: el.wellComplained,
          yearPlanServiced: el.yearPlanServiced,
          percentage: (el.wellComplained / el.totalWells) * 100,
          percentageYearPlanServiced: (el.yearPlanServiced / el.totalWells) * 100,
        };
      } else {
        uniqItems[el.client.fullName].totalWells += el.totalWells;
        uniqItems[el.client.fullName].wellComplained += el.wellComplained;
        uniqItems[el.client.fullName].yearPlanServiced += el.yearPlanServiced;
        uniqItems[el.client.fullName].percentage =
          (uniqItems[el.client.fullName].wellComplained / uniqItems[el.client.fullName].totalWells) * 100;
        uniqItems[el.client.fullName].percentageYearPlanServiced =
          (uniqItems[el.client.fullName].yearPlanServiced / uniqItems[el.client.fullName].totalWells) * 100;
      }
    });
    const sortedUniqItems = sortClients(uniqItems)
    return Object.values(sortedUniqItems);
  }
  if (mode === "fields") {
    const clientFields = fields.filter((el) => el.client.id === id);

    return clientFields.map(el => ({ ...el, percentage: el.wellComplained / el.totalWells * 100, percentageYearPlanServiced: el.yearPlanServiced / el.totalWells * 100 }));
  }
  if (mode === "wells") {
    const field = fields.find((el) => el.id === id) || { wells: [] };

    return field.wells.map((el) => ({ ...el, name: el.wellNumber }));
  }
};

export const getDashboardClickOnItemNavigationPath = (mode, id) => {
  // if (mode === 'wells') {
  //   return `/reports/${id}`
  // }
  if (mode === 'wells') {
    return `/well-state/${id}`
  }
  return false
}

export const getDashboardItemHref = (mode, id) => {
  if (mode === "clients" || mode === "") {
    return "#fields=" + id;
  }
  if (mode === "fields") {
    return "#wells=" + id;
  }
  if (mode === "wells") {
    return "#wells=" + id;
  }

  return "#";
};

export const getMapItems = (mode, fields, id) => {
  if (fields.length === 0) return [];

  if (mode === "clients" || mode === "") {
    const uniqItems = {};
    fields.forEach((el) => {
      if (!(el.client.fullName in uniqItems)) {
        uniqItems[el.client.fullName] = {
          ...el.client,
          name: el.client.fullName,
          coordinates: el.coordinates,
          wellComplained: el.wellComplained,
          yearPlanServiced: el.yearPlanServiced,
          totalWells: el.totalWells,
        };
      } else {
        uniqItems[el.client.fullName].totalWells += el.totalWells;
        uniqItems[el.client.fullName].wellComplained += el.wellComplained;
        uniqItems[el.client.fullName].yearPlanServiced += el.yearPlanServiced;
        uniqItems[el.client.fullName].percentage =
          (uniqItems[el.client.fullName].wellComplained / uniqItems[el.client.fullName].totalWells) * 100;
        uniqItems[el.client.fullName].percentageYearPlanServiced =
          (uniqItems[el.client.fullName].yearPlanServiced / uniqItems[el.client.fullName].totalWells) * 100;
      }
    });

    return Object.values(uniqItems);
  }
  if (mode === "fields") {
    const clientFields = fields.filter((el) => el.client.id === id);

    return clientFields.map((el) => el);
  }
  if (mode === "wells") {
    const field = fields.find((el) => el.id === id);

    return field.wells.map((el) => ({ ...el, name: el.wellNumber }));
  }

  if (mode === "ONLY_CURRENT_FIELD") {
    return [fields.find((el) => el.id === id)]
  }
};

export const getMapPanelInfo = (mode, fields, me, id) => {
  if (!fields.length || !id) return { title: me?.employee?.client?.fullName };

  if (mode === "clients") {
    return { title: me.employee.client.fullName };
  }
  if (mode === "fields") {
    const field = fields.find((el) => el.client.id === id);

    return { title: field.client.fullName, desciption: "", showTable: false };
  }
  if (mode === "wells") {
    const field = fields.find((el) => el.id === id);
    let reports = [];

    field.wells.forEach((well) => {
      if (well.reports.length) {
        const reportsToAdd = well.reports.map((report) => ({
          id: report.id,
          wellNumber: well.wellNumber,
          status: report.status,
        }));

        reports = [...reports, ...reportsToAdd];
      }
    });

    return { title: field.client.fullName, desciption: field.name, showTable: true, tableItems: reports };
  }
};