import { Button, TextInput } from "@carbon/react";
import { selectMap, updateMapState } from "../../redux/reducers/MapSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import styles from "./MapSearch.module.css";
import { useState, useEffect } from "react";
import stylesHeader from "../Header/Header.module.css";

import { getMapItems } from '../../helpers'

export default function MapSearch() {
  const dispatch = useAppDispatch();
  const fieldsDefault = useAppSelector(selectMap).fieldsDefault;
  const fields = useAppSelector(selectMap).fields || [];
  const me = useAppSelector(selectMap).me;
  //const fields = useAppSelector(selectMap).fields
  const [field, setField] = useState("");
  const [well, setWell] = useState("");

  const onChange = (e, setFunc) => {
    setFunc(e.target.value);
  };
  let clientUrl = me?.employee?.client?.iconUrl

  const role = JSON.parse(localStorage.getItem("tokens"))?.user?.role;
  const onSearch = () => {
    const fieldsCopy = [...fieldsDefault];
    const filteredFields = fieldsCopy.filter(el => el.name.includes(field));
    const filterResult = [];

    filteredFields.forEach(el => {
      const wells = el.wells.filter(item => {
        const wellNumber = item.wellNumber || "";

        return wellNumber.includes(well)
      });

      if (wells.length) {
        filterResult.push({ ...el, wells: wells });
      }
    });

    dispatch(updateMapState({ fields: filterResult }))
  }
  const mode = window.location.hash.slice(1).split("=")[0];
  const id = window.location.hash.slice(1).split("=")[1];

  if (mode === 'fields') {
    const items = getMapItems(mode, fields, id);
    clientUrl = items.length ? items[0].client.iconUrl : ''
  }

  if (mode === 'wells') {
    const items = getMapItems("ONLY_CURRENT_FIELD", fields, id);
    clientUrl = items.length ? items[0].client.iconUrl : ''
  }

  // const myFunction = () => {
  //   // your logic here
  //   console.log('pressed Enter ✅');
  // };
  // const keyDownHandler = event => {
  //   console.log('User pressed: ', event.key);

  //   if (event.key === 'Enter') {
  //     event.preventDefault();
  //     console.log(well, field)
  //     if (well.length || field.length) {
  //       // 👇️ your logic here
  //       myFunction();
  //     }

  //   }
  // };
  useEffect(() => {
    const myFunction = () => {
      // your logic here
      console.log('pressed Enter ✅');
      onSearch()
    };
    const keyDownHandler = event => {
      console.log('User pressed: ', event.key);

      if (event.key === 'Enter') {
        event.preventDefault();
        console.log(well, field)
        if (well.length || field.length) {
          // 👇️ your logic here
          myFunction();
        }

      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [well, field]);

  return (
    <section class={styles.search}>
      <div className={styles.filters}>
        <TextInput
          id="fieldInput"
          light="true"
          labelText="Field"
          placeholder="Fields"
          value={field}
          onChange={(e) => {
            onChange(e, setField);
          }}
        />
        <TextInput
          id="wellInput"
          light="true"
          labelText="Well"
          placeholder="Wells"
          value={well}
          onChange={(e) => {
            onChange(e, setWell);
          }}
        />
      </div>
      <div className={stylesHeader["logo-div"]}>
        {role === "client" && clientUrl && <img className={stylesHeader.logo} src={clientUrl} />}
      </div>
      <div className={styles.title}> <span>Wellhead Integrity Platform</span></div>
      <div className={styles.title}> <span></span></div>
      <div className={styles.title}> <span></span></div>
      <div className={styles.title}> <span></span></div>
      <div className={styles.title}> <span></span></div>
      <div className={styles.actions}>
        <Button id="searchBtn" style={{ height: "100%" }} size="xl" onClick={onSearch}>Search</Button>
      </div>
    </section>
  );
}
