import styles from "./ThirdStep.module.css";
import { DatePicker, TextArea, DatePickerInput } from "@carbon/react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectReport, updateReportState } from "../../redux/reducers/ReportSlice";

export default function ThirdStep() {
  const dispatch = useAppDispatch();
  const report = useAppSelector(selectReport);
  const onFieldChange = (fieldName, value = "") => {
    console.log(fieldName, value);
    dispatch(updateReportState({ [fieldName]: value }));
  };

  return (
    <>
      <DatePicker
        onChange={(e) => { onFieldChange("nextVisit", e[0]?.getTime() || 0) }}
        className={styles["date-picker"]}
        width="100%"
        dateFormat="d.M.Y"
        datePickerType="single"
        value={+report.nextVisit ? +report.nextVisit : new Date()}
      >
        <DatePickerInput
          style={{ width: "100%" }}
          id="date-picker-calendar-id"
          placeholder="mm.dd.yyyy"
          labelText="Next visit"
          type="text"
        />
      </DatePicker>
      <TextArea
        labelText="Recommendation"
        placeholder="Comments"
        rows={4}
        maxCount={400}
        enableCounter={true}
        id="text-area-1"
        value={report.reportsDescriptions}
        onChange={(e) => onFieldChange("reportsDescriptions", e.target.value)}
      />
    </>
  );
}
