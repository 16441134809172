import { axiosPrivate } from "./axios/axiosPrivate";
import { GetObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3';

export default class MapService {
  static token = null;

  static async getLocalStorageAccessToken() {
    const tokens = JSON.parse(localStorage.getItem("tokens"));

    if (!tokens.accessToken) {
      throw new Error("NEED_LOGIN");
    }
    return tokens.accessToken;
  }

  static async getLocalStorageRefreshToken() {
    const tokens = JSON.parse(localStorage.getItem("tokens"));

    if (!tokens.refreshToken) {
      throw new Error("NEED_LOGIN");
    }
    return tokens.refreshToken;
  }

  static async updateLocalStorageTokens(tokens) {
    localStorage.setItem(tokens, JSON.stringify(tokens));
  }

  static async authentificateUser(email, password) {
    const token = this.token;
    const resp = await fetch(`${process.env.REACT_APP_API_URL}/api/users/signin`, {
      method: "POST",
      body: JSON.stringify({ ...{ email, password }, ...(!!token && token) }),
    });
    const json = await resp.json();

    console.log(json, this.token);
  }

  static async signin(email, password) {
    const resp = await axiosPrivate.post(`${process.env.REACT_APP_API_URL}/api/users/signin`, {
      email,
      password,
    });
    if (!resp.data.accessToken) {
      throw new Error("BAD_LOGIN_OR_PASSWORD");
    }
    this.token = resp.data.accessToken;

    return resp.data;
  }

  static async getReport(id) {
    const { accessToken } = JSON.parse(localStorage.getItem("tokens"));
    if (!accessToken) {
      throw new Error("NEED_LOGIN");
    }

    const resp = await axiosPrivate.get(`${process.env.REACT_APP_API_URL}/api/reports/${id}`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
    if (!resp.data) {
      throw new Error("BAD_LOGIN_OR_PASSWORD");
    }

    return resp.data;
  }
  static async getReports(page = 1, pageSize = 50, defaultFilter) {
    const { accessToken } = JSON.parse(localStorage.getItem("tokens"));
    if (!accessToken) {
      throw new Error("NEED_LOGIN");
    }
    const resp = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/api/reports?${defaultFilter ? `status=${defaultFilter}&` : ""}page=${page}&pageSize=${pageSize}`,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!resp.data) {
      throw new Error("BAD_LOGIN_OR_PASSWORD");
    }
    return resp.data;
  }

  static async createNewReport(rawReport) {
    const report = {};
    for (const [key, value] of Object.entries(Object.assign({}, rawReport))) {
      if (value) {
        console.log("attach", key, value);
        report[key] = value;
      }
    }

    const { accessToken } = JSON.parse(localStorage.getItem("tokens"));

    if (!accessToken) {
      throw new Error("NEED_LOGIN");
    }

    if (report.location) {
      report.latitude = report.location.split(",")[0];
      report.longitude = report.location.split(",")[1];
    }

    const resp = await axiosPrivate.post(`${process.env.REACT_APP_API_URL}/api/reports`, report, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
    if (!resp.data) {
      throw new Error("CREATE_ERROR");
    }
    return resp.data;
  }

  static async updateReport(rawReport) {
    const report = {};
    for (const [key, value] of Object.entries(Object.assign({}, rawReport))) {
      if (value) {
        console.log("attach", key, value);
        report[key] = value;
      }
    }

    const accessToken = this.getLocalStorageAccessToken();

    if (report.location) {
      report.latitude = report.location.split(",")[0];
      report.longitude = report.location.split(",")[1];
    }
    console.log("onUpdate", report);
    const resp = await axiosPrivate.patch(
      `${process.env.REACT_APP_API_URL}/api/reports`,
      Object.assign(report, { reportId: report.id }),
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );
    console.log(resp);
    if (!resp.data) {
      throw new Error("CREATE_ERROR");
    }
    return resp.data;
  }

  static async deleteReport(rawReport) {
    const reportId = rawReport.id

    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.delete(
      `${process.env.REACT_APP_API_URL}/api/reports/${reportId}`,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );
    console.log(resp);
    if (!resp.data) {
      throw new Error("delete");
    }
    return resp.data;
  }


  static async updateReportState(id, isApprove) {
    const action = isApprove ? "approve" : "reject";
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.patch(
      `${process.env.REACT_APP_API_URL}/api/engineer-on-charge/${action}`,
      { reportId: id },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return resp.data;
  }

  static async sendReportForSubmit(id) {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.patch(
      `${process.env.REACT_APP_API_URL}/api/well-head-super-visor/submit/`,
      { reportId: id },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return resp.data;
  }

  static async updateReportChanges(report) {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.patch(
      `${process.env.REACT_APP_API_URL}/api/reports`,
      { ...report, reportId: report.id },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return resp.data;
  }

  static async getMapInfo() {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.get(`${process.env.REACT_APP_API_URL}/api/clients/`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
    console.log(resp.data);
    return resp.data;
  }

  static async uploadFile(file, id) {
    const accessToken = this.getLocalStorageAccessToken();
    const formData = new FormData();

    formData.append("file", file);
    formData.append("reportId", id);

    const resp = await axiosPrivate.post(`${process.env.REACT_APP_API_URL}/api/reports/upload`, formData, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    return resp.data;
  }

  static async uploadFileToS3(file, {
    region,
    bucketName,
    accessKeyId,
    secretAccessKey,
    dirName,
    sessionToken
  }) {

    const config = {
      bucketName,
      region,
      dirName,
      accessKeyId,
      secretAccessKey,
      sessionToken,
    }

    const client = new S3Client({
      region: config.region,
      credentials: {
        secretAccessKey: config.secretAccessKey,
        accessKeyId: config.accessKeyId,
        sessionToken: config.sessionToken
      },
    });
    const uploadParams = {
      Bucket: config.bucketName,
      Key: `${config.dirName}/${file.name}`, // Set the desired path and filename in S3
      Body: file,
      ACL: 'public-read',
      ContentDisposition: "inline",
      ContentType: file.type
    };
    const command = new PutObjectCommand(uploadParams);
    const response = await client.send(command);
    return `https://${config.bucketName}.s3.${config.region}.amazonaws.com/${config.dirName}/${file.name}`
  }

  static async getFullList(withHidden = false) {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.get(`${process.env.REACT_APP_API_URL}/api/clients/full-info/?withHidden=${withHidden}`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    return resp.data;
  }

  static async saveMetadata(metadata) {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.post(`${process.env.REACT_APP_API_URL}/api/files/save-metadata`, metadata, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    return resp.data;
  }

  static async getUploadCredentials(reportId) {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.post(`${process.env.REACT_APP_API_URL}/api/files/url`, {
      reportId
    }, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    return resp.data;
  }

  static async removeFile(reportId, fileId) {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.patch(
      `${process.env.REACT_APP_API_URL}/api/files`,
      { reportId, fileId },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return resp.data;
  }

  static async downloadFile({
    fileName,
    mimetype,
    region,
    dirName,
    Bucket,
    AccessKeyId,
    SecretAccessKey,
    SessionToken
  }) {

    const config = {
      bucketName: Bucket,
      region,
      dirName,
      accessKeyId: AccessKeyId,
      secretAccessKey: SecretAccessKey,
      sessionToken: SessionToken,
    }

    const client = new S3Client({
      region: config.region,
      credentials: {
        secretAccessKey: config.secretAccessKey,
        accessKeyId: config.accessKeyId,
        sessionToken: config.sessionToken
      },
    });

    const params = {
      Bucket: config.bucketName,
      Key: `${config.dirName}/${fileName}`, // The key (path) of the file in S3
    };
    console.log('-------', params)
    // Use the getObject command to download the file
    const command = new GetObjectCommand(params);

    const response = await client.send(command)

    const blob = new Blob([response.Body], {
      type: mimetype
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.download = fileName;
    link.href = url;
    link.click();

    // Release the object URL to free up resources
    window.URL.revokeObjectURL(url);

    console.log('PDF file downloaded successfully');

  }

  static async viewFile(id, type, name, downloadUrl) {
    const accessToken = this.getLocalStorageAccessToken();
    console.log('------', downloadUrl)

    if (downloadUrl) {
      try {
        const resp = await fetch(downloadUrl)
        const blob = await resp.blob();
        const dataUrl = URL.createObjectURL(blob);
        window.open(dataUrl)
      } catch (e) { console.log(e) }

    } else {
      const resp = await axiosPrivate({
        method: 'GET',
        headers: {
          "Accept": "application/octet-stream, application/json, text/plain, */*"
        },
        url: `${process.env.REACT_APP_API_URL}/api/reports/download/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([resp.data], {
        type: type
      });
      const url = window.URL.createObjectURL(blob);

      window.open(url)
    }


  }

  static async searchReports(value, page, pageSize, defaultFilter) {
    const accessToken = this.getLocalStorageAccessToken();
    const fields = ["field", "well", "client", "createdAt", "createdBy"];
    const queryParams = fields.map((field) => `${field}=${value}`);

    if (defaultFilter) {
      const combinedStatus = [...defaultFilter, value].join(",");
      queryParams.push(`status=${combinedStatus}`);
    } else {
      queryParams.push(`status=${value}`);
    }

    const resp = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/api/reports?${queryParams.join("&")}&page=${page}&pageSize=${pageSize}`,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return resp.data;
  }

  static async getReportsByWellId(id, page, pageSize) {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/api/reports?wellId=${id}&page=${page}&pageSize=${pageSize}`,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return resp.data;
  }

  static async getMe() {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/api/users/me`,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return resp.data;
  }
  static async getFieldsList(page, pageSize) {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.get(`${process.env.REACT_APP_API_URL}/api/fields/?page=${page}&pageSize=${pageSize}`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    return resp.data;
  }

  static async getFieldById(id) {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/api/fields/field/${id}`,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return resp.data;
  }
  static async updateFieldChanges(field) {
    const accessToken = this.getLocalStorageAccessToken();
    const payload = field.id ? { ...field, fieldId: field.id } : { ...field }
    const execute = field.id ? axiosPrivate.patch : axiosPrivate.post
    const resp = await execute(
      `${process.env.REACT_APP_API_URL}/api/fields`,
      payload,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return resp.data;
  }


  static async searchFields(value, page, pageSize, defaultFilter) {
    const accessToken = this.getLocalStorageAccessToken();
    const fields = ["name"];
    const queryParams = fields.map((field) => `${field}=${value}`);

    const resp = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/api/fields?${queryParams.join("&")}&page=${page}&pageSize=${pageSize}`,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return resp.data;
  }

  static async getWellsList(page, pageSize) {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.get(`${process.env.REACT_APP_API_URL}/api/wells/?page=${page}&pageSize=${pageSize}`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    return resp.data;
  }

  static async getWellsByStatus(status) {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/api/wells/status/${status}`,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return resp.data;
  }

  static async getWellById(id) {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/api/wells/${id}`,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return resp.data;
  }

  static async getFieldsNamesList() {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/api/fields/list`,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return resp.data;
  }

  static async deleteWell(wellId) {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.delete(
      `${process.env.REACT_APP_API_URL}/api/wells/${wellId}`,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return resp.data;
  }

  static async updateWellChanges(well) {
    const accessToken = this.getLocalStorageAccessToken();
    const payload = well.id ? { ...well, wellId: well.id } : { ...well }
    const execute = well.id ? axiosPrivate.patch : axiosPrivate.post
    const resp = await execute(
      `${process.env.REACT_APP_API_URL}/api/wells`,
      payload,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (resp.data.status > 399) {
      throw new Error(resp.data.message)
    }
    return resp.data;
  }

  static async createWellChanges(well) {
    const accessToken = this.getLocalStorageAccessToken();
    const resp = await axiosPrivate.post(
      `${process.env.REACT_APP_API_URL}/api/wells`,
      { ...well },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (resp.data.status > 399) {
      throw new Error(resp.data.message)
    }
    return resp.data;
  }

  static async searchWells(value, page, pageSize, defaultFilter) {
    const accessToken = this.getLocalStorageAccessToken();
    const fields = ["well"];
    const queryParams = fields.map((field) => `${field}=${value}`);

    const resp = await axiosPrivate.get(
      `${process.env.REACT_APP_API_URL}/api/wells?${queryParams.join("&")}&page=${page}&pageSize=${pageSize}`,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return resp.data;
  }

  static async validateWellByNumberAndFieldName({ well, field }) {
    const accessToken = this.getLocalStorageAccessToken();
    const payload = { wellNumber: well.wellNumber, fieldName: field.name }
    const execute = axiosPrivate.post
    const resp = await execute(
      `${process.env.REACT_APP_API_URL}/api/wells/validate`,
      payload,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (resp.data.status > 399) {
      throw new Error(resp.data.message)
    }
    return resp.data;
  }

  static async validateFieldByNameAndClientId({ client, field }) {
    const accessToken = this.getLocalStorageAccessToken();
    const payload = { field, client }
    const execute = axiosPrivate.post
    const resp = await execute(
      `${process.env.REACT_APP_API_URL}/api/fields/validate`,
      payload,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (resp.data.status > 399) {
      throw new Error(resp.data.message)
    }
    return resp.data;
  }

  static async updateFieldAndWellChanges(changes) {
    const accessToken = this.getLocalStorageAccessToken();
    const payload = changes.id ? { ...changes } : { ...changes }
    const execute = changes.id ? axiosPrivate.patch : axiosPrivate.post
    const resp = await execute(
      `${process.env.REACT_APP_API_URL}/api/changes`,
      payload,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (resp.data.status > 399) {
      throw new Error(resp.data.message)
    }
    return resp.data;
  }
}
