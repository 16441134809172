import styles from "./ReportContent.module.css";

import { useNavigate } from "react-router-dom";

import FormElement from "../FormElement/FormElement";
import CommentsElement from "../CommentsElement/CommentsElement";
import AttachmentsElement from "../AttachmentsElement/AttachmentsElement";
import { useEffect, useState } from "react";
import { Button, FlexGrid, Column, Row } from "@carbon/react";
import { STATUSES } from "../../data/constants";
import {
  OPERATION,
  WELL_HEAD_SIZE,
  WELL_TYPE,
  PRODUCTION_DRIVE,
  WELL_INTEGRITY_SERVICE,
  WELL_INTEGRITY_SERVICE_TO_SERVICE_TYPE,
  REPAIR_SERVICES,
} from "../../data/fields";

import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { selectReport, updateReportState, flushReport } from "../../redux/reducers/ReportSlice";
import { selectReportContent, updateReportContentState } from "../../redux/reducers/ReportContentSlice";
import MapService from "../../API/service";

import EditPlacement from './editPlacement'

import { getCurrentState, getWellStatus } from './helpers'


export default function ReportContent({ onActionClick, onFieldChange, submitChanges }) {
  const navigate = useNavigate();

  const report = useAppSelector(selectReport);
  const { well } = useAppSelector(selectReportContent);
  const dispatch = useAppDispatch();
  const role = JSON.parse(localStorage.getItem("tokens")).user.role;
  const [editMode, setEditMode] = useState(false);
  const [showPlacement, setShowPlacement] = useState(false)

  const onReportActionClick = (isApprove) => {
    onActionClick(isApprove);
  };
  const onReportSubmit = async (id) => {
    const resp = await MapService.sendReportForSubmit(id);

    dispatch(updateReportState(resp));

  } // Replace with your actual updatedAt timestamp

  const onReportDelete = async (rawReport) => {
    const resp = await MapService.deleteReport(rawReport)
    if (resp.reportId) {
      dispatch(flushReport)
      navigate("/");
      return
    }
    alert('Something goes wrong with delete')
  }

  const isUpdatedWithin24Hours = (Date.now() - +report.rejectedDate) < 24 * 60 * 60 * 1000;
  const deleteVisible = !editMode && (role === "engineerOnCharge" || role === "wellHeadSuperVisor") && (role === "engineerOnCharge" && report.status !== STATUSES.draft) || (role === "wellHeadSuperVisor" && report.status === STATUSES.draft)
  console.log(!editMode && (role === "engineerOnCharge" || role === "wellHeadSuperVisor") && (role === "wellHeadSuperVisor" && report.status === STATUSES.draft))
  const editVisible =
    // (role === "engineerOnCharge" && report.status !== STATUSES.approved && report.status !== STATUSES.reject) ||
    (role === "engineerOnCharge" && report.status !== STATUSES.draft) ||
    (role === "wellHeadSuperVisor" && (report.status === STATUSES.draft || (report.status === STATUSES.reject && isUpdatedWithin24Hours)));
  const actionsVisivle =
    role === "engineerOnCharge" && !editMode && report.status !== STATUSES.approved && report.status !== STATUSES.reject;
  const submitBtnVisible = role === "wellHeadSuperVisor" && !editMode && (report.status === STATUSES.reject || report.status === STATUSES.draft);
  console.log(report.wellIntegrityService, WELL_INTEGRITY_SERVICE_TO_SERVICE_TYPE, WELL_INTEGRITY_SERVICE_TO_SERVICE_TYPE[report.wellIntegrityService])
  const numVisits = report && report.attachments && report.attachments.reduce((sum, attachment) => attachment.originName.match(/whm/gi) ? sum += 1 : sum, 0) || 0

  const { actualLocation, actualFieldName, actualWellNumber } = report.well ? getCurrentState(report, report.changes, editVisible) : { actualLocation: report.location, actualFieldName: '', actualWellNumber: '' }


  return (
    <section>
      {showPlacement && <EditPlacement showModal={showPlacement} setShowModal={setShowPlacement} />}
      <FormElement
        label={"WHS No"}
        fieldValue={report?.createdBy?.user?.username}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
      />
      <FormElement
        label={"Engineer In Charge"}
        fieldValue={
          report.rejectedBy
            ? report.rejectedBy.user.username
            : report.approvedBy
              ? report.approvedBy.user.username
              : "Not reviewed yet"
        }
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
      />
      {report && <FormElement
        label={"Status"}
        fieldValue={report.status == 'Submitted' ? getWellStatus(report) ? report.status : 'Overdue' : report.status}
        underscored={true}
        editMode={editMode}
        onFieldChange={onFieldChange}
      />}

      {/* <FormElement
        label={"Well compliance"}
        fieldToUpdate={"wellCompliance"}
        fieldValue={report.wellCompliance}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
        fieldType={"switch"}
      /> */}
      <FormElement
        label={"Location"}
        fieldToUpdate={"location"}
        fieldValue={report?.well && actualLocation}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
        disabled={true}
      />
      <FormElement
        label={"Client"}
        fieldValue={report?.well?.field?.client?.fullName}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
      />
      <FormElement
        label={"Field"}
        fieldValue={report?.well?.field && actualFieldName}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
      />
      <FlexGrid>
        <Row>
          <Column sm={2} md={2} lg={2}>
            <FormElement
              label={"Well number"}
              fieldValue={report?.well && actualWellNumber}
              underscored={false}
              editMode={editMode}
              onFieldChange={onFieldChange}
            />
            {editMode && <Button onClick={() => {
              setShowPlacement(true)
            }} kind="primary" tabIndex={0} size="sm" type="button" role="button" >
              edit
            </Button>}
          </Column>
        </Row>
      </FlexGrid>
      <FormElement
        label={"Well head size"}
        fieldToUpdate={"wellHeadSize"}
        fieldValue={report.wellHeadSize}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
        fieldType={"combobox"}
        fieldValues={WELL_HEAD_SIZE}
      />
      <FormElement
        label={"Visits"}
        fieldToUpdate={"numVisit"}
        fieldValue={numVisits || report.numVisit}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
        disabled={true}
      />

      <FormElement
        label={"Rig Number"}
        fieldToUpdate={"operations"}
        fieldValue={report.operations}
        underscored={true}
        editMode={editMode}
        onFieldChange={onFieldChange}
        fieldType={"operations"}
        fieldValues={OPERATION}
      />
      <FormElement
        label={"Well type"}
        fieldToUpdate={"wellType"}
        fieldValue={report.wellType}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
        fieldType={"combobox"}
        fieldValues={WELL_TYPE}
      />
      <FormElement
        label={"Production drive"}
        fieldToUpdate={"productionDrive"}
        fieldValue={report.productionDrive}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
        fieldType={"combobox"}
        fieldValues={PRODUCTION_DRIVE}
      />
      <FormElement
        label={"Well integrity service"}
        fieldToUpdate={"wellIntegrityService"}
        fieldValue={report.wellIntegrityService}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
        fieldType={"combobox"}
        fieldValues={WELL_INTEGRITY_SERVICE}
      />
      <FormElement
        label={"Service type"}
        fieldToUpdate={"serviceType"}
        fieldValue={WELL_INTEGRITY_SERVICE_TO_SERVICE_TYPE[report.wellIntegrityService].includes(report.serviceType) ? report.serviceType : WELL_INTEGRITY_SERVICE_TO_SERVICE_TYPE[report.wellIntegrityService][0]}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
        fieldType={"combobox"}
        fieldValues={WELL_INTEGRITY_SERVICE_TO_SERVICE_TYPE[report.wellIntegrityService]}
      />
      <FormElement
        label={"Well Head Clamp installation"}
        fieldToUpdate={"wellHeadRemedialClampRequired"}
        fieldValue={report.wellHeadRemedialClampRequired}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
        fieldType={"switch"}
        options={["Required", "Not Required", "Not Checked"]}
      />
      <FormElement
        label={"Cellar available"}
        fieldToUpdate={"cellarAvailability"}
        fieldValue={report.cellarAvailability}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
        fieldType={"switch"}
      />
      <FormElement
        label={"Cellar required"}
        fieldToUpdate={"cellarRequired"}
        fieldValue={report.cellarRequired}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
        fieldType={"switch"}
      />
      <FormElement
        label={"Work required"}
        fieldToUpdate={"extraWorkRequired"}
        fieldValue={report.extraWorkRequired}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
        fieldType={"switch"}
      />
      <FormElement
        label={"Repair services"}
        fieldToUpdate={"repairService"}
        fieldValue={report.repairService}
        underscored={true}
        editMode={editMode}
        onFieldChange={onFieldChange}
        fieldType={"multiSelect"}
        fieldValues={REPAIR_SERVICES}
        disabled={report.extraWorkRequired !== "Yes"}
      />
      <FormElement
        label={"Next visit"}
        fieldToUpdate={"nextVisit"}
        fieldValue={report.nextVisit}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
        fieldType={"date"}
      />

      {editMode ? <FormElement
        label={"Recommendation"}
        fieldToUpdate={"reportsDescriptions"}
        fieldValue={report.reportsDescriptions}
        underscored={false}
        editMode={editMode}
        onFieldChange={onFieldChange}
        fieldType={"Recommendation"}
      /> : <CommentsElement label={"Recommendation"} text={report.reportsDescriptions} />}


      {/* <CommentsElement label={"Recommendation"} editMode={editMode} fieldToUpdate={"reportsDescriptions"} onFieldChange={onFieldChange} fieldValue={report.reportsDescriptions} /> */}
      <AttachmentsElement label={"Attachments"} editMode={editMode} attachments={report.attachments || []} />

      <div className={styles.actions}>
        {actionsVisivle ? (
          <>
            <Button
              onClick={() => {
                onReportActionClick(false);
              }}
              kind="danger"
              style={{ visibility: role === "engineerOnCharge" && !editMode ? "visible" : "hidden" }}
            >
              Reject
            </Button>
            <Button
              onClick={() => {
                onReportActionClick(true);
              }}
              kind="primary"
              style={{ visibility: role === "engineerOnCharge" && !editMode ? "visible" : "hidden" }}
            >
              Submit
            </Button>
          </>
        ) : (
          <></>
        )}
        {
          submitBtnVisible && <Button
            onClick={() => {
              onReportSubmit(report.id);
            }}
            kind="primary"
            style={{ visibility: submitBtnVisible ? "visible" : "hidden" }}
          >
            Submit
          </Button>
        }
        {deleteVisible ? <Button
          onClick={() => {
            onReportDelete(report)
          }}
          kind="danger"
        >
          Delete
        </Button> : <></>}
        {editVisible ? (
          <Button
            onClick={async () => {
              setEditMode(!editMode);
              if (editMode) {
                await MapService.updateReportChanges({ ...report, well })
              }
            }}
            kind="primary"
          >
            {editMode ? "Save" : "Edit"}
          </Button>
        ) : (
          <></>
        )}
      </div>
    </section >
  );
}
