import { ContainedList, ContainedListItem, Modal, Button } from "@carbon/react";

import { setBusy, getBusy } from "../../redux/reducers/BusySlice";

import {
  selectWellsWithState,
  updateWellsStatusState,
} from "../../redux/reducers/WellWithStatus";
import {
  selectFieldsWithState,
  updateFieldsStatusState,
} from "../../redux/reducers/FieldWithStatus";
import { selectAppHeader, updateModal } from "../../redux/reducers/AppHeader";

import { useAppSelector, useAppDispatch } from "../../redux/hooks";

import MapService from "../../API/service";

export default () => {
  const dispatch = useAppDispatch();
  const wellsWithState = useAppSelector(selectWellsWithState);
  const fieldWithState = useAppSelector(selectFieldsWithState);
  const busyStatus = useAppSelector(getBusy);
  const { showModalWithChanges } = useAppSelector(selectAppHeader);

  const getButtons = (onSubmit, onReject) => (
    <div>
      <Button onClick={onSubmit} size={"sm"}>
        Submit
      </Button>
      <Button onClick={onReject} size={"sm"} kind="secondary">
        Reject
      </Button>
    </div>
  );

  const wellClickHandler = async (well, status) => {
    const wellOnUpdate = { id: well.id, status };

    wellOnUpdate.status = status;
    try {
      dispatch(setBusy(true));
      await MapService.updateWellChanges(wellOnUpdate);
      const { wells } = await MapService.getWellsByStatus("Unapproved");
      dispatch(updateWellsStatusState({ unapproved: wells }));
      dispatch(setBusy(false));
    } catch (e) {}
    dispatch(setBusy(false));
  };

  const fieldClickHandler = async (field, status) => {
    const fieldOnUpdate = { id: field.id, status };
    try {
      dispatch(setBusy(true));
      await MapService.updateFieldChanges(fieldOnUpdate);
      const { fields } = await MapService.getWellsByStatus("Unapproved");
      dispatch(updateFieldsStatusState({ unapproved: fields }));
      dispatch(setBusy(false));
    } catch (e) {}
    dispatch(setBusy(false));
  };

  const listOfChanges = fieldWithState.unapproved.map((entity) => (
    <ContainedListItem>
      Field {entity.name} will added to {entity.client.fullName}{" "}
      {!busyStatus
        ? getButtons(
            () => fieldClickHandler(entity, "Approved"),
            () => fieldClickHandler(entity, "Rejected")
          )
        : "Waiting while resolve prev operation"}
    </ContainedListItem>
  ));
  listOfChanges.push(
    ...wellsWithState.unapproved.map((entity) => (
      <ContainedListItem>
        Well {entity.wellNumber} will added to {entity.field.name}
        {!busyStatus
          ? getButtons(
              () => wellClickHandler(entity, "Approved"),
              () => wellClickHandler(entity, "Rejected")
            )
          : "Waiting while resolve prev operation"}
      </ContainedListItem>
    ))
  );
  return (
    <Modal
      modalHeading="Add a custom domain"
      modalLabel="Account resources"
      primaryButtonText="Add"
      secondaryButtonText="Cancel"
      open={showModalWithChanges}
      onRequestClose={(e) => {
        e.stopPropagation();
        dispatch(updateModal(false));
      }}
    >
      <ContainedList>{listOfChanges}</ContainedList>
    </Modal>
  );
};
