import { createSlice } from "@reduxjs/toolkit";

export const stepperSlice = createSlice({
    name: "stepper",
    initialState: { currentStep: 0, isEditMode: false },
    reducers: {
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setMode: (state, action) => {
            state.isEditMode = action.payload === 'edit'
        }
    },
});

export const { setCurrentStep, setMode } = stepperSlice.actions

export const selectCurrentStep = (state) => state.stepper.currentStep;

export const selectStepperMode = (state) => state.stepper.isEditMode;


export default stepperSlice.reducer;