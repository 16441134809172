import React, { Component } from "react";
import { redirect } from "react-router-dom";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error or perform other actions here
    console.error("Error caught:", error, errorInfo);
    //this.setState({ hasError: true });
    localStorage.clear();
    redirect("/login");
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;