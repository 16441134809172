import AppHeader from "../components/Header/AppHeader";
import ReportForm from "../components/ReportForm/ReportForm";
import ReportHeader from "../components/ReportHeader/ReportHeader";
import ReportContent from "../components/ReportContent/ReportContent";
import MapService from "../API/service";
import { useParams } from "react-router";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectReport, updateReportState } from "../redux/reducers/ReportSlice";
import { selectReportContent, updateReportContentState } from "../redux/reducers/ReportContentSlice";


import moment from "moment";

import {
  WELL_INTEGRITY_SERVICE_TO_SERVICE_TYPE,
} from "../data/fields";

export default function ReportPage() {
  const dispatch = useAppDispatch();
  const report = useAppSelector(selectReport);
  const { id } = useParams();
  const logo = "/rep-logo.png";
  const role = JSON.parse(localStorage.getItem("tokens")).user.role;
  const onActionClick = async (isApprove) => {
    const resp = await MapService.updateReportState(id, isApprove);

    dispatch(updateReportState(resp));
  };
  const onFieldChange = (fieldName, fieldValue) => {
    if (fieldName === "extraWorkRequired" && fieldValue === "No") {
      dispatch(updateReportState({ ...report, repairService: [], [fieldName]: fieldValue }));
    } else {
      if (fieldName === "wellIntegrityService") {
        dispatch(updateReportState({ ...report, [fieldName]: fieldValue, serviceType: WELL_INTEGRITY_SERVICE_TO_SERVICE_TYPE[fieldValue][0] }));
      } else {

        if (fieldName === 'location') {
          const [latitude, longitude] = fieldValue.split(',')
          console.log([latitude, longitude])
          if (!latitude || !longitude) {
            dispatch(updateReportState({
              ...report,
              well: {
                ...report.well,
                latitude: latitude,
                longitude: longitude
              }
            }));
          } else {
            dispatch(updateReportState({
              ...report,
              well: {
                ...report.well,
                latitude: latitude.trim(),
                longitude: longitude.trim()
              }
            }));
          }

        } else {
          dispatch(updateReportState({ ...report, [fieldName]: fieldValue }));
        }

      }

    }
  };
  const submitChanges = async (id) => {
    const resp = await MapService.updateReport(report);

    if (role === "wellHeadSuperVisor") {
      await MapService.sendReportForSubmit(id);
    }
  };

  useEffect(() => {
    const getReport = async (id) => {
      const currentRepport = await MapService.getReport(id);
      dispatch(updateReportState(currentRepport));
      dispatch(updateReportContentState({ well: { ...currentRepport.well, location: currentRepport.location }, client: currentRepport?.well?.field?.client, field: currentRepport?.well?.field, changes: currentRepport.changes, disableWellChoose: false }))
    };

    getReport(id);
  }, []);
  console.log('-----', report);
  return (
    <section className="report-section">
      <AppHeader />
      <ReportForm
        header={
          <ReportHeader
            title="Well integrity report"
            description={report.createdAt ? moment(new Date(report.createdAt)).format("DD.MMM.YYYY") : ""}
            iconUrl={report?.well?.field?.client?.iconUrl}
          />
        }
        content={
          <ReportContent
            onActionClick={onActionClick}
            report={report}
            onFieldChange={onFieldChange}
            submitChanges={submitChanges}
          />
        }
      />
    </section>
  );
}
