import AppHeader from "../components/Header/AppHeader";
import ReportForm from "../components/ReportForm/ReportForm";
import ReportHeader from "../components/ReportHeader/ReportHeader";
import ReportContent from "../components/ReportContent/ReportContent";
import MapService from "../API/service";
import { useParams } from "react-router";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectReport, updateReportState } from "../redux/reducers/ReportSlice";

import WellContent from '../components/WellContent/WellContent'

export default function WellPage() {
    const { id } = useParams();
    useEffect(() => {
        // const getReport = async (id) => {
        //     const currentRepport = await MapService.getReport(id);

        //     dispatch(updateReportState(currentRepport));
        // };

        // getReport(id);
    }, []);
    return (
        <section className="report-section">
            <AppHeader />
            <WellContent wellId={id} />
        </section>
    );
}
