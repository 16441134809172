import styles from "./Dashboard.module.css";
import ProgressIndicator from "../ProgressIndicator/ProgressIndicator";
import { Breadcrumb, BreadcrumbItem, FormLabel, Button } from "@carbon/react";
import { selectMap, updateMapState, changeMeter } from "../../redux/reducers/MapSlice";
import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getDashboardItems, getDashboardItemHref, getDashboardClickOnItemNavigationPath } from "../../helpers";
import { MAP_MODE } from "../../data/constants";
import { useNavigate } from 'react-router-dom'

export default function Dashboard() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isMeterOnComplianceMode = useAppSelector(selectMap).isMeterOnComplianceMode

  const whatCalculate = isMeterOnComplianceMode ? 'percentage' : 'percentageYearPlanServiced'
  const whatMode = isMeterOnComplianceMode ? 'Wellhead Compliance' : 'Wells Service PM Yearly Plan'
  const whatPalletToUse = isMeterOnComplianceMode ? 'colorCompliance' : 'colorYearPlanService'
  const STATUSES = {
    'colorCompliance': {
      good: 'Serviced',
      bad: 'Overdue',
    },
    'colorYearPlanService': {
      good: "Serviced",
      bad: "Overdue"
    }
  }

  const mode = window.location.hash.slice(1).split("=")[0];
  const id = window.location.hash.slice(1).split("=")[1];
  const fields = useAppSelector(selectMap).fields || [];
  let items = getDashboardItems(mode, fields, id);
  const clientId =
    mode === MAP_MODE.fields ? id : mode === MAP_MODE.wells ? fields.find(el => el.id === id)?.client?.id : "";
  const fieldId = MAP_MODE.wells ? id : "";

  const listItems = items.map((item, index) => (
    <div key={item.id} className={styles["list-item"]}>
      {mode === MAP_MODE.wells && <label className={styles.unselectable}>{index + 1}</label>}
      {mode === MAP_MODE.wells ? (
        <span onClick={(e) => { e.preventDefault(); console.log(item.id, item.name); getDashboardClickOnItemNavigationPath(mode, item.id) ? navigate(getDashboardClickOnItemNavigationPath(mode, item.id)) : console.log(item.id, item.name, 'not accessable way') }} className={styles["list-item-name-span"]}> {item.name}</span>
      ) : (
        <a href={getDashboardItemHref(mode, item.id)} className={styles["list-item-name"]}>
          {item.name}
        </a>
      )}
      {mode !== MAP_MODE.wells && <ProgressIndicator value={Math.round(item[whatCalculate])} />}
      {mode !== MAP_MODE.wells && <span>{Math.round(item[whatCalculate])} %</span>}
      {mode === MAP_MODE.wells && <span style={{ color: item[whatPalletToUse] }}>{item[whatPalletToUse] === "red" ? STATUSES[whatPalletToUse].bad : STATUSES[whatPalletToUse].good}</span>}
    </div >
  ));

  const setMeter = () => {
    dispatch(changeMeter())
  }

  return (
    <section className={styles.dashboard}>
      <div className={styles.breadcrumb}>
        <Breadcrumb noTrailingSlash>
          <BreadcrumbItem href="#clients" isCurrentPage={mode === "clients"}>
            Clients
          </BreadcrumbItem>
          {(mode === "fields" || mode === "wells") && (
            <BreadcrumbItem
              href={"#fields=" + clientId}
              isCurrentPage={mode === "fields"}
              className={styles[mode === "fields" ? "a-current" : "link"]}
            >
              Fields
            </BreadcrumbItem>
          )}
          {mode === "wells" && (
            <BreadcrumbItem
              href={"#wells=" + fieldId}
              isCurrentPage={mode === "wells"}
              className={styles[mode === "wells" ? "a-current" : "link"]}
            >
              Wells
            </BreadcrumbItem>
          )}
        </Breadcrumb>
        {mode !== MAP_MODE.wells ? <div><Button onClick={() => setMeter()}>{
          whatMode
        }</Button></div> : ``}
      </div>
      <div className={styles["dashboard-items-div"]}>
        {mode === MAP_MODE.wells && <div className={styles["list-header-item"]}>
          <span className={styles["list-header-name-span"]}>#</span>
          <span className={styles["list-header-name-span"]}>Well</span>
          <span className={styles["list-header-name-span-last"]}>Status</span>
        </div>}
        {listItems}
      </div>
    </section>
  );
}
