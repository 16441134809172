import { useState } from "react";
import {
  FormLabel,
  ContentSwitcher,
  Switch,
  TextInput,
  ComboBox,
  Dropdown,
  Button,
  Modal,
  FlexGrid,
  Column,
  Row,
  ModalFooter,
  ModalBody,
} from "@carbon/react";

import MapService from "../../API/service";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setBusy } from "../../redux/reducers/BusySlice";
import {
  selectReport,
  updateReportState,
} from "../../redux/reducers/ReportSlice.js";
import {
  selectReportContent,
  updateReportContentState,
} from "../../redux/reducers/ReportContentSlice.js";

import { validateLocation, parseLocation, getCurrentState } from "./helpers";
import LocationChange from "./locationChange";

export default () => {
  const report = useAppSelector(selectReport);
  let { wells, fields, well, field, client, disableWellChoose, changes } =
    useAppSelector(selectReportContent);

  const dispatch = useAppDispatch();
  const [showNewWellInput, setShowNewWellInput] = useState(false);
  const [showEditWellInput, setEditWellInput] = useState(false);
  const [isNewWellNumberValid, setNewWellNumberValid] = useState(true);
  const [isEditWellNumberValid, setEditWellNumberValid] = useState(true);

  const handleIntentionToAddNewEntity = () => {
    setShowNewWellInput(!showNewWellInput);
    setEditWellInput(false);
    setNewWellNumberValid(false);
    setEditWellNumberValid(false);
    dispatch(
      updateReportContentState({
        well: { ...well },
        disableWellChoose:
          !showEditWellInput && !!showNewWellInput ? false : true,
      })
    );
  };

  const handleIntentionToEditWellNumber = () => {
    setEditWellInput(!showEditWellInput);
    setShowNewWellInput(false);
    setNewWellNumberValid(false);
    setEditWellNumberValid(false);
    dispatch(
      updateReportContentState({
        well: {
          ...well,
          // wellNumber: report.well.wellNumber,
          // latitude: report.well.latitude,
          // longitude: report.well.longitude,
        },
        disableWellChoose:
          !!showEditWellInput && !showNewWellInput ? false : true,
      })
    );
  };

  const handleTypeNewWellNumber = (e) => {
    dispatch(
      updateReportContentState({
        well: { ...well, wellNumber: e.target.value },
      })
    );
  };

  const handleTypeEditWellName = (e) => {
    dispatch(
      updateReportContentState({
        well: { ...well, wellNumber: e.target.value },
      })
    );
  };

  const handleValidationNewWell = async () => {
    const isLocationValid = validateLocation(well.location);
    if (!isLocationValid) {
      setNewWellNumberValid(false);
      return;
    }

    if (!well.wellNumber.length) {
      setNewWellNumberValid(false);
      return;
    }

    const [latitude, longitude] = parseLocation(well.location);
    dispatch(
      updateReportContentState({
        well: {
          ...well,
          latitude,
          longitude,
        },
      })
    );
    dispatch(setBusy(true));
    const payload = {
      well: { ...well },
      field,
    };
    const response = await MapService.validateWellByNumberAndFieldName(payload);
    if (response.isValid) {
      setNewWellNumberValid(true);
      console.log(response);
    } else {
      setNewWellNumberValid(false);
      alert(response.reason);
    }
    dispatch(setBusy(false));
  };

  const handleCreationNewWell = async () => {
    const isLocationValid = validateLocation(well.location);
    if (!isLocationValid) {
      setEditWellNumberValid(false);
      return;
    }
    if (!well.wellNumber.length) {
      setNewWellNumberValid(false);
      return;
    }
    const [latitude, longitude] = parseLocation(well.location);
    dispatch(setBusy(true));

    const payload = {
      well: { ...well },
      field,
    };
    const response = await MapService.validateWellByNumberAndFieldName(payload);
    if (!response.isValid) {
      setNewWellNumberValid(false);
      dispatch(setBusy(false));
      alert(response.reason);
      return;
    }

    try {
      const payload = {
        field: field.name,
        latitude: latitude.trim(),
        longitude: longitude.trim(),
        wellNumber: well.wellNumber,
      };
      const wellCreated = await MapService.updateWellChanges(payload);
      const res = await MapService.getFullList(true);
      console.log("222222", res);
      console.log(well);
      const fields = res.find((el) => el.fullName === client.fullName).fields;

      const wells = fields.find((el) => el.name === field.name).wells;

      dispatch(updateReportContentState({ clients: res, fields, wells }));
      dispatch(
        updateReportContentState({
          well: { ...well, location: `${well.latitude},${well.longitude}` },
          disableWellChoose: false,
        })
      );

      setShowNewWellInput(false);
      setNewWellNumberValid(false);
      setEditWellNumberValid(false);
    } catch (error) {
      alert(error);
    }
    dispatch(setBusy(false));
  };

  const handleValidationEditWell = async () => {
    const isLocationValid = validateLocation(well.location);
    if (!isLocationValid) {
      setEditWellNumberValid(false);
      return;
    }

    if (!well.wellNumber.length) {
      setEditWellNumberValid(false);
      return;
    }

    const [latitude, longitude] = parseLocation(well.location);
    dispatch(
      updateReportContentState({
        well: {
          ...well,
          latitude,
          longitude,
        },
      })
    );
    dispatch(setBusy(true));
    const payload = {
      well: { ...well },
      field: { ...field },
    };
    const response = await MapService.validateWellByNumberAndFieldName(payload);
    if (response.isValid) {
      setEditWellNumberValid(true);
      console.log(response);
    } else {
      setEditWellNumberValid(false);
      alert(response.reason);
    }
    dispatch(setBusy(false));
  };

  const handleSaveEditWell = async () => {
    const isLocationValid = validateLocation(well.location);
    if (!isLocationValid) {
      setEditWellNumberValid(false);
      return;
    }
    if (!well.wellNumber.length) {
      setNewWellNumberValid(false);
      return;
    }
    const [latitude, longitude] = parseLocation(well.location);
    dispatch(setBusy(true));

    const payload = {
      well: {
        ...well,
      },
      field,
    };
    const response = await MapService.validateWellByNumberAndFieldName(payload);
    if (!response.isValid) {
      setNewWellNumberValid(false);
      dispatch(setBusy(false));
      alert(response.reason);
      return;
    }

    try {
      const payload = {
        reportId: report.id,
        toState: {
          well: {
            ...well,
            latitude: latitude.trim(),
            longitude: longitude.trim(),
          },
          field,
        },
        fromState: { well: report.well, field: report.well.field },
      };
      const changesCreated = await MapService.updateFieldAndWellChanges(
        payload
      );
      const res = await MapService.getFullList(true);
      console.log("222222", res);
      console.log(well);
      const fields = res.find((el) => el.fullName === client.fullName).fields;

      const wells = fields.find((el) => el.id === field.id).wells;

      const {
        actualWellNumber,
        actualLocation,
        actualFieldLocation,
        actualFieldName,
      } = getCurrentState(report, changes, true);

      dispatch(
        updateReportContentState({
          clients: res,
          fields,
          wells,
          well: {
            ...well,
            wellNumber: actualWellNumber,
            location: actualLocation,
          },
          field: {
            ...field,
          },
          disableWellChoose: false,
        })
      );

      const currentRepport = await MapService.getReport(report.id);
      dispatch(updateReportState(currentRepport));

      setShowNewWellInput(false);
      setNewWellNumberValid(false);
      setEditWellNumberValid(false);
    } catch (error) {
      alert(error);
    }
    dispatch(setBusy(false));
  };

  const onWellChange = (name) => {
    if (name) {
      const data = wells.find((el) => el.wellNumber === name);

      dispatch(updateReportContentState({ well: data }));
    }
  };
  console.log("wellChange", well, wells);
  return (
    <div>
      <div>{well && <span>Well number {well.wellNumber}</span>}</div>
      <ComboBox
        onChange={(e) => {
          // onFieldChange("wellNumber", e.selectedItem);
          onWellChange(e.selectedItem);
        }}
        id="wellNumber"
        items={wells.map((el) => el.wellNumber)}
        itemToString={(item) => item || ""}
        titleText="Well number"
        selectedItem={well && well.wellNumber}
        disabled={disableWellChoose}
      />
      <Button onClick={handleIntentionToAddNewEntity}>
        {showNewWellInput ? "hide" : "add new well"}
      </Button>
      {well && (
        <Button onClick={handleIntentionToEditWellNumber}>
          {showEditWellInput ? "hide" : "edit well"}
        </Button>
      )}
      {showNewWellInput && (
        <div>
          <TextInput
            data-modal-primary-focus
            id="well-create"
            type="text"
            labelText="Create new well"
            placeholder="Type well number"
            style={{
              marginBottom: "1rem",
            }}
            value={well && well.wellNumber}
            onChange={handleTypeNewWellNumber}
            invalid={!isNewWellNumberValid}
            invalidText="Need correct well number"
          />
          <LocationChange isOpen={true} />
          {isNewWellNumberValid ? (
            <Button onClick={handleCreationNewWell}>Create</Button>
          ) : (
            <Button onClick={handleValidationNewWell}>Validate</Button>
          )}
        </div>
      )}
      {showEditWellInput && (
        <div>
          <TextInput
            data-modal-primary-focus
            id="well-edit"
            type="text"
            labelText="Edit well number"
            placeholder="Type well number"
            style={{
              marginBottom: "1rem",
            }}
            value={well && well.wellNumber}
            onChange={handleTypeEditWellName}
            invalid={!isEditWellNumberValid}
            invalidText="Need correct well number"
          />
          <LocationChange isOpen={true} />
          {isEditWellNumberValid ? (
            <Button onClick={handleSaveEditWell}>Save</Button>
          ) : (
            <Button onClick={handleValidationEditWell}>Validate</Button>
          )}
        </div>
      )}
    </div>
  );
};
