import AppHeader from "../components/Header/AppHeader";
import ReportsTable from "../components/ReportsTable/ReportsTable";
import MapService from "../API/service";
import moment from "moment";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { STATUSES } from "../data/constants";
import { useAppDispatch } from "../redux/hooks";
import { DECLINED_COLUMNS } from "../data/constants";
import { calculateRemainingTime, setBusyState } from "../helpers/index.js"

export default function List() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const headers = DECLINED_COLUMNS;
  const items = reports.map((report) => ({
    id: report.id,
    well: report.well.wellNumber,
    field: report.well.field.name,
    customer: report.well.field.client.fullName,
    revDate: moment(report.rejectedBy.updatedAt).format("DD.MMM.YYYY"),
    revBy: report.rejectedBy.user.username,
    term: calculateRemainingTime(new Date(+report.rejectedDate)),
  }));
  const onPageChange = async (e) => {
    const result = await MapService.getReports(e.page, pageSize, `status=${STATUSES.reject}`);

    setPage(e.page);
    setReports(result.items);
  };
  
  useEffect(() => {
    const getReports = async () => {
      try {
        const resp = await MapService.getReports(page, pageSize, STATUSES.reject);

        setReports(resp.items);
        setTotalItems(resp.totalItems);
        setPage(+resp.page);
        setBusyState(dispatch, false)
      } catch (error) {
        setBusyState(dispatch, false)
      }
    };

    setBusyState(dispatch, true)
    getReports();
  }, []);

  return (
    <section className="list-section">
      <AppHeader />
      <ReportsTable
        title={`Declined reports - ${items.length}`}
        headerFields={headers}
        totalItems={totalItems}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        rows={items}
        disableAdd={true}
        onRowClick={(id) => navigate(`/report/${id}`)}
      />
    </section>
  );
}
