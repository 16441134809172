import styles from "./LoginForm.module.css";
import { useState } from "react";
import { Form, Stack, TextInput, TextArea, Section, Heading, Button } from "@carbon/react";
import Logo from "../Logo/Logo";
import noc from "./noc.png";

export default function LoginForm({ onSubmit }) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(login, password);
    setLogin("");
    setPassword("");
  };
  const handleChange = (e, setFunc) => {
    setFunc(e.target.value);
  };

  return (
    <section className={styles["login-form"]}>
      <div>
        <img src={noc} />
      </div>
      <Form onSubmit={onFormSubmit}>
        <Stack gap={7}>
          <Section level={2}>
            <Logo />
          </Section>
          <TextInput
            helperText="Enter email or employee name"
            id="login"
            value={login}
            onChange={(e) => handleChange(e, setLogin)}
            invalidText="Invalid login or employee name"
            labelText="Login"
            placeholder=""
          />
          <TextInput
            id="password"
            value={password}
            onChange={(e) => handleChange(e, setPassword)}
            type="password"
            invalidText="Invalid password"
            labelText="Password"
            placeholder=""
          />
          <Button kind="primary" tabIndex={0} type="submit">
            Submit
          </Button>
        </Stack>
      </Form>
    </section>
  );
}
