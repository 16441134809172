import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: null,
    well: null,
    nextVisit: 0,
    operations: "Rigless",
    numVisit: 0,
    wellHeadSize: null,
    wellType: null,
    productionDrive: null,
    wellIntegrityService: "Well Head Maintenance",
    wellHeadRemedialClampRequired: "Not Checked",
    serviceType: null,
    cellarAvailability: "Yes",
    cellarRequired: "Yes",
    wellCompliance: "Yes",
    workRequired: [],
    wellHeadRemedial: [],
    nonRoutineWork: [],
    workshopWork: [],
    status: null,
    createdBy: 0,
    createdAt: 0,
    location: null,
    latitude: null,
    longitude: null,
}

export const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers: {
        flushReport: (state) => {
            state = initialState
        },
        updateReportState: (state, action) => {
            console.log('0000', action.payload)

            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value
            }
        },
        updateToDefault: (state) => {
            for (const [key, value] of Object.entries(initialState)) {

                state[key] = value
            }
        }
    },
});

export const { updateReportState, updateToDefault, flushReport } = reportSlice.actions

export const selectReport = (state) => state.report;

export default reportSlice.reducer;